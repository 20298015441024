// import { ethers } from 'ethers'
import { useMemo } from 'react'
// import erc1155Abi from 'src/lib/erc1155.json'
// import erc20Abi from 'src/lib/erc20Abi.json'
// import erc721Abi from 'src/lib/erc721Abi.json'
import {
  XypeRegistry__factory,
  XypeRegistrar__factory,
  XypeResolver__factory,
  RootNodeController__factory,
  XypeRegistrar
} from 'src/contract-types'

// import routerAbi from '../lib/routerAbi.json';

// import { currencies, SixthSocietySaleAddress, AccessAddress, SixthSocietyNFT } from '../constants/currency';
// import { networks } from '../constants/currency'
import { contractAddresses } from '../constants'
// import { TTokenType } from '../types'
import useMetaMask from './useMetaMask'

// const getProviderOrSigner = async (provider: any, account?: string) => {
//   return account ? await provider?.getSigner(account) : provider
// }

export const useRegistryContract = () => {
  const { signer, chainId, account } = useMetaMask()

  return useMemo(() => {
    const contract = XypeRegistry__factory.connect(contractAddresses[chainId].BNSRegistry, signer)
    return contract
  }, [signer, chainId, account])
}

export const useRegistrarContract = (address = '') => {
  const { signer, account } = useMetaMask()

  return useMemo(() => {
    if (address) {
      const contract = XypeRegistrar__factory.connect(address, signer)
      return contract
    }
    return {} as XypeRegistrar
  }, [signer, account, address])
}

export const useResolverContract = () => {
  const { signer, chainId, account } = useMetaMask()

  return useMemo(() => {
    const contract = XypeResolver__factory.connect(contractAddresses[chainId].BNSResolver, signer)

    return contract
  }, [signer, chainId, account])
}

export const useControllerContract = () => {
  const { signer, chainId, account } = useMetaMask()

  return useMemo(() => {
    const contract = RootNodeController__factory.connect(
      contractAddresses[chainId].RootNodeController,
      signer
    )

    return contract
  }, [signer, chainId, account])
}

// export function useTokenContracts() {
//   const { provider, chainId, account } = useMetaMask()

//   return useMemo(() => {
//     const currencies = networks?.[chainId as keyof typeof networks]?.currencies
//     return Object.entries(currencies).map(([symbol, currency]) => {
//       const contract = new ethers.Contract(
//         currency.token,
//         erc20Abi,
//         getProviderOrSigner(provider, account)
//       )
//       return {
//         symbol,
//         contract,
//         path: currency.path,
//         icon: currency.icon
//       }
//     })
//   }, [provider, chainId, account])
// }
