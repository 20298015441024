import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useForm, FormProvider, FieldValues, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddIcon from '@mui/icons-material/Add'
import {
  keccak256,
  toUtf8Bytes,
  solidityPackedKeccak256,
  AbiCoder,
  Interface,
  namehash
} from 'ethers'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import primaryIcon from 'src/assets/primary.svg'
import deleteIcon from 'src/assets/delete.svg'
import setPrimaryIcon from 'src/assets/set-primary.svg'
import { Alias } from 'src/types'
import CustomModal from 'src/components/modal/custom-modal'
import { ButtonPrimary, FormField, ButtonPrimaryOutlined, Dropdown } from 'src/components/common'
import { ALIAS_ENCODE, MEDIA_WIDTHS, UserRoles } from 'src/constants'
import { colors } from 'src/constants/colors'
import { useIsOpen } from 'src/hooks/use-is-open'
import { ConfirmModal } from 'src/components/modal/confirm-modal'
import { useRegistrarContract, useResolverContract } from 'src/hooks/useContracts'
import useMetaMask from 'src/hooks/useMetaMask'
import { Registrar__factory } from 'src/contract-types'
import apiService from 'src/services/api'
import { useDomainData } from 'src/hooks/use-domain-data'
import LoadingIndicator from 'src/components/loading-indicator'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { errorToast } from 'src/utils'

import { validationSchema } from './validation-schema'
import { Tooltip } from '@mui/material'

interface Props {
  handleClose: (needRefresh?: boolean) => void
  initialValues: Alias | null
  isReserve?: boolean
}

export const AliasModal = ({ handleClose, initialValues, isReserve = false }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [primaryAlias, setPrimaryAlias] = useState<string | number>(
    initialValues?.primaryAlias ? initialValues?.primaryAlias.split('.')[0] : ''
  )
  const [availability, setAvailability] = useState<Record<string, string>>({})
  const [initialAliases, setInitialAliases] = useState<Alias[]>([])

  const timeout = useRef(0 as any)

  const { id, contractAddress, name } = useDomainData()
  const { domain = '' } = useParams<{ domain?: string }>()

  const { account } = useMetaMask()

  const { isOpen: isConfirmOpen, close: closeConfirm, open: openConfirm } = useIsOpen()

  const contract = useRegistrarContract(contractAddress)
  const resolverContract = useResolverContract()

  const { isAllowed: isAllowedToChangeWallet } = useHaveAccess({
    roles: [UserRoles.SuperAdmin, UserRoles.Owner]
  })

  const formMethods = useForm({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues: {
      ...(!isReserve && { walletAddress: initialValues?.ownerAddress || '' }),
      aliases: [{ value: '', id: '' }]
    },
    resolver: yupResolver(validationSchema(isReserve))
  })

  const { fields, append, remove } = useFieldArray({
    name: 'aliases',
    control: formMethods.control
  })

  const values = formMethods.watch()
  const { errors } = formMethods.formState

  const isEnableSubmitBtn = useMemo(() => {
    const newAliases = values.aliases.filter(({ value }) => {
      const exists = initialAliases.find((el) => value === el.name)
      return !exists
    })

    return newAliases.every(({ value }) => availability[value] === 'Available')
  }, [JSON.stringify(values.aliases), availability])

  useEffect(() => {
    const fetchAddressAliases = async () => {
      try {
        setIsLoading(true)
        const res = await apiService.getAliases({
          id,
          search: initialValues?.ownerAddress || '',
          page: 1,
          offset: 1000
        })
        const [aliases, availability] = res.data.items.reduce(
          ([al, av]: [{ value: string }[], Record<string, boolean>], item: Alias) => {
            const name = item.name.split('.')[0]
            return [[...al, { value: name, id: item.id }], { ...av, [name]: 'Registered' }]
          },
          [[], {}]
        )
        append(aliases)
        setAvailability(availability)
        setInitialAliases(
          res.data.items.map((el: Alias) => ({ ...el, name: el.name.split('.')[0] }))
        )
        remove(0)
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    }
    if (initialValues?.ownerAddress) {
      fetchAddressAliases()
    } else if (initialValues?.name) {
      const { name, id } = initialValues

      append({
        value: name,
        id
      })
      setAvailability({
        [name]: 'Available'
      })
      remove(0)
    }
  }, [initialValues?.ownerAddress])

  const getPrimaryName = async () => {
    try {
      if (!values.walletAddress) return
      const node = namehash(values.walletAddress.substring(2).toLowerCase() + '.addr.reverse')
      const name = await resolverContract.name(node)

      if (!name) {
        setPrimaryAlias(values.aliases[0].value || 0)
      } else {
        setPrimaryAlias('')
      }
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    if (initialValues) return
    if (values.walletAddress) {
      getPrimaryName()
    } else {
      setPrimaryAlias('')
    }
  }, [values.walletAddress, initialValues])

  const submitForm = async (values: FieldValues) => {
    try {
      closeConfirm()
      setIsLoading(true)

      if (isReserve) {
        await apiService.reserveAlias({
          id,
          aliases: values.aliases.map((alias: { value: string }) => ({ label: alias.value }))
        })

        toast.success('Alias reserved successfully')
        handleClose(true)

        return
      }

      interface AliasLabel {
        label: string
        duration: number
        isPrimary: boolean
      }

      const domain = await apiService.getDomain(id)
      const {
        payment: { subscription, type },
        expiresAt
      } = domain.data

      const durationExpiresAt = Math.floor(
        moment.duration(moment(expiresAt).diff(moment())).asSeconds()
      )
      const durationSubscription = Math.floor(
        moment
          .duration(
            moment()
              .add(subscription === 'monthly' ? 1 : 12, 'months')
              .diff(moment())
          )
          .asSeconds()
      )

      let duration = durationExpiresAt

      if (type === 'subscription' && subscription) {
        duration = durationSubscription
      }

      const aliasesToDelete = initialAliases.reduce((acc: string[], next) => {
        const exists =
          values.aliases.find((el: { value: string }) => el.value === next.name) &&
          initialValues?.ownerAddress === values.walletAddress
        if (!exists) {
          acc.push(next.name)
        }
        return acc
      }, [])

      const aliasesToAdd = values.aliases.reduce(
        (acc: AliasLabel[], alias: { value: string; id: string }, index: number) => {
          const exists =
            initialAliases.find((el) => alias.value.toLowerCase() === el.name.toLowerCase()) &&
            initialValues?.ownerAddress === values.walletAddress

          if (!exists) {
            const initialExpiryDate = initialAliases.find((el) => el.id === alias.id)?.expiryDate

            acc.push({
              label: alias.value.toLowerCase(),
              duration: initialExpiryDate
                ? Math.floor(moment.duration(moment(initialExpiryDate).diff(moment())).asSeconds())
                : duration,
              isPrimary: primaryAlias === alias.value || primaryAlias === index
            })
          }
          return acc
        },
        []
      ) as AliasLabel[]

      const bnsInterface = new Interface(Registrar__factory.abi)
      const multicall = []

      if (aliasesToDelete.length) {
        const txDataDelete = bnsInterface.encodeFunctionData('deleteAlias', [
          aliasesToDelete,
          values.walletAddress,
          true
        ])

        multicall.push(txDataDelete)
      }

      const DOMAIN_SEPARATOR = await contract.DOMAIN_SEPARATOR()
      const REGISTER_ON_BEHALF_TYPEHASH = await contract.REGISTER_ON_BEHALF_TYPEHASH()

      const nonce = await contract.userNonce(
        process.env.REACT_APP_OPERATOR_ADDRESS || '0x21688958cb0D4CDC4E3dB166965A459fb4e88f1E'
      )
      const deadline = BigInt(Math.round(new Date().getTime() / 1000) + 30 * 60)

      const digest = solidityPackedKeccak256(
        ['bytes1', 'bytes1', 'bytes32', 'bytes32'],
        [
          '0x19',
          '0x01',
          DOMAIN_SEPARATOR,
          keccak256(
            AbiCoder.defaultAbiCoder().encode(
              ['bytes32', 'address', ALIAS_ENCODE, 'address', 'uint256', 'uint256'],
              [
                REGISTER_ON_BEHALF_TYPEHASH,
                account,
                aliasesToAdd,
                values.walletAddress,
                nonce,
                deadline
              ]
            )
          )
        ]
      )

      const signature = await apiService.addAlias({
        id,
        wallet: values.walletAddress,
        aliases: aliasesToAdd,
        digest
      })

      if (aliasesToAdd.length) {
        // const tx = await contract.registerOnBehalf({
        //   labels: aliasesToAdd,
        //   to: values.walletAddress,
        //   operator: process.env.REACT_APP_OPERATOR_ADDRESS,
        //   nonce,
        //   deadline,
        //   v: signature.data.v,
        //   r: Uint8Array.from(signature.data.r.data),
        //   s: Uint8Array.from(signature.data.s.data)
        // })
        // await tx.wait()
        // handleClose(true)
        // return

        const txDataRegister = bnsInterface.encodeFunctionData('registerOnBehalf', [
          {
            labels: aliasesToAdd,
            to: values.walletAddress,
            operator:
              process.env.REACT_APP_OPERATOR_ADDRESS ||
              '0x21688958cb0D4CDC4E3dB166965A459fb4e88f1E',
            nonce,
            deadline,
            v: signature.data.v,
            r: Uint8Array.from(signature.data.r.data),
            s: Uint8Array.from(signature.data.s.data)
          }
        ])

        multicall.push(txDataRegister)
      }

      if (
        initialValues &&
        primaryAlias &&
        initialValues?.primaryAlias.split('.')[0] !== primaryAlias &&
        !aliasesToAdd.some((alias) => alias.label.toLowerCase() === primaryAlias)
      ) {
        const txDataSetPrimary = bnsInterface.encodeFunctionData('setPrimary', [
          primaryAlias,
          values.walletAddress
        ])

        multicall.push(txDataSetPrimary)
      }

      const res = await contract.multicall(multicall)
      await res.wait()

      const backRequests = []

      if (aliasesToAdd.length) {
        backRequests.push(
          apiService.addAliasLog({
            id,
            aliases: aliasesToAdd.map((alias) => `${alias.label}.${name}`),
            wallet: values.walletAddress
          })
        )
      }

      if (aliasesToDelete.length) {
        backRequests.push(
          apiService.deleteAliasLog({
            id,
            aliases: aliasesToDelete.map((alias) => `${alias}.${name}`),
            wallet: values.walletAddress
          })
        )
      }

      if (primaryAlias && initialValues?.primaryAlias?.split('.')?.[0] !== primaryAlias) {
        backRequests.push(
          apiService.changePrimaryAliasLog({
            id,
            wallet: values.walletAddress
          })
        )
      }

      await Promise.all(backRequests)

      if (initialValues?.status === 'reserved') {
        await apiService.deleteReservedAlias(initialValues.id)
      }

      toast.success(initialValues ? 'Changes saved successfully' : 'Alias added successfully')
      handleClose(true)
    } catch (error: any) {
      console.log('log => error', error)

      errorToast(error, contract)
    } finally {
      setIsLoading(false)
    }
  }

  const onConfirm = () => {
    submitForm(values)
  }

  const isAliasAvailable = async (name: string) => {
    if (!name || availability[name] !== undefined) return

    clearTimeout(timeout.current)
    timeout.current = setTimeout(async () => {
      try {
        setIsLoading(true)
        const res = await contract.isAliasAvailable(keccak256(toUtf8Bytes(name.toLowerCase())))
        const isReserved = await apiService.existAlias(id, name.toLowerCase())
        const stopWords = await apiService.getStopWords(id, name.toLowerCase())
        const isStopWord = stopWords?.data?.words?.length
          ? stopWords?.data?.words
              ?.split(',')
              ?.some((word: string) => name.toLowerCase().includes(word.toLowerCase()))
          : false

        let availableText = 'Available'

        if (!res || isReserved.data) {
          availableText = 'Registered'
        } else if (isStopWord) {
          availableText = 'Inappropriate'
        }
        if (availability[name] !== availableText) {
          setAvailability((state) => ({ ...state, [name]: availableText }))
        }
      } catch (error) {
        console.log('log => error', error)
      } finally {
        setIsLoading(false)
      }
    }, 250)
  }

  const showsAvailabilityText = (value: string, index: number) => {
    if (availability[value] === undefined || initialValues?.name === value) return false

    if (!initialValues) return true

    const isNew = !initialAliases.find((alias) => alias.name === value)
    return isNew
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <ConfirmModal
        title={
          isReserve
            ? 'Do you want to reserve Alias?'
            : initialValues
            ? 'Do you want to save changes in Alias?'
            : 'Do you want to add Alias?'
        }
        isOpen={isConfirmOpen}
        onConfirm={onConfirm}
        onClose={closeConfirm}
      />
      <CustomModal
        isOpen
        handleClose={handleClose}
        title={isReserve ? 'Reserve Alias' : initialValues ? 'Edit Alias' : 'Add Alias'}
      >
        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(openConfirm)}>
            {!isReserve && (
              <FormField
                disabled={Boolean(initialValues?.ownerAddress) ? !isAllowedToChangeWallet : false}
                name="walletAddress"
                label="Wallet address"
                placeholder="Specify the wallet"
                value={values.walletAddress}
              />
            )}
            {fields.map((field, index) => {
              const { value } = values.aliases[index]
              const error = errors.aliases?.[index]?.value?.message
              const isPrimary = (value && value === primaryAlias) || primaryAlias === index

              return (
                <InputContainer
                  {...(error && {
                    style: {
                      alignItems: 'center'
                    }
                  })}
                >
                  <CustomPlaceholderContainer>
                    <FormField
                      afterChange={isAliasAvailable}
                      key={field.id}
                      name={`aliases.${index}.value`}
                      label="Alias Name"
                      InputProps={{
                        style: { zIndex: 2 },
                        endAdornment: value && showsAvailabilityText(value, index) && (
                          <AliasStatus
                            style={{
                              color:
                                availability[value] === 'Available'
                                  ? colors.$success
                                  : colors.$error
                            }}
                          >
                            {availability[value]}
                          </AliasStatus>
                        ),
                        autoComplete: 'off'
                      }}
                      value={value}
                      error={error}
                      {...(isPrimary &&
                        !isReserve && {
                          labelIcon: (
                            <Tooltip title="Primary alias" placement="top" arrow>
                              <img
                                src={primaryIcon}
                                alt="primaryIcon"
                                style={{ marginLeft: '8px' }}
                              />
                            </Tooltip>
                          )
                        })}
                      valueSuffix={
                        value && (
                          <AliasSuffix>
                            <span className="name">{value}</span>
                            {domain}
                          </AliasSuffix>
                        )
                      }
                    />

                    {!value && (
                      <div
                        className="placeholder"
                        {...(error && {
                          style: {
                            bottom: '44px'
                          }
                        })}
                      >
                        <div>youraddress</div>
                        <div>{domain}</div>
                      </div>
                    )}
                  </CustomPlaceholderContainer>
                  {(fields.length > 1 || (!isPrimary && !isReserve && value)) && (
                    <Dropdown
                      menuContent={
                        <ItemsContainer>
                          {!isPrimary && !isReserve && value && (
                            <Item
                              onClick={() => {
                                setPrimaryAlias(value)
                              }}
                            >
                              <img src={setPrimaryIcon} alt="editIcon" />
                              Set as primary
                            </Item>
                          )}
                          {fields.length > 1 && (
                            <Item
                              onClick={() => {
                                if (isPrimary) {
                                  const newPrimary = values.aliases.find(
                                    (alias) => alias.value !== value
                                  )
                                  if (newPrimary) {
                                    setPrimaryAlias(newPrimary.value)
                                  }
                                }
                                remove(index)
                              }}
                            >
                              <img src={deleteIcon} alt="deleteIcon" />
                              Delete
                            </Item>
                          )}
                        </ItemsContainer>
                      }
                    >
                      <MoreVertIcon fontSize="small" className="moreIcon" />
                    </Dropdown>
                  )}
                </InputContainer>
              )
            })}
            <ButtonPrimaryOutlined
              sx={{
                width: '160px',
                lineHeight: '16px',
                padding: '6px 24px !important',
                minHeight: '32px !important'
              }}
              onClick={() => {
                append({ value: '', id: '' }, { shouldFocus: false })
              }}
              endIcon={<AddIcon sx={{ width: '20px' }} />}
            >
              Add Alias
            </ButtonPrimaryOutlined>
            <div className="actions">
              {initialValues ? (
                <>
                  <ButtonPrimaryOutlined onClick={() => handleClose()} fullWidth>
                    Cancel
                  </ButtonPrimaryOutlined>
                  <ButtonPrimary type="submit" fullWidth disabled={!isEnableSubmitBtn}>
                    Save Changes
                  </ButtonPrimary>
                </>
              ) : (
                <>
                  <ButtonPrimaryOutlined onClick={() => handleClose()} fullWidth>
                    Cancel
                  </ButtonPrimaryOutlined>
                  <ButtonPrimary type="submit" fullWidth disabled={!isEnableSubmitBtn}>
                    {isReserve ? 'Reserve' : 'Add Alias'}
                  </ButtonPrimary>
                </>
              )}
            </div>
          </Form>
        </FormProvider>
      </CustomModal>
    </>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .moreIcon {
    fill: ${colors.$blue};
    cursor: pointer;
  }
  .actions {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
      flex-direction: column;
    }
  }
  .dropdown-children {
    height: 48px;
    display: flex;
    align-items: center;
  }
`

const InputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  .value {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
    font-size: 16px;
    line-height: 16px;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  div {
    width: 175px;
  }
`

const Item = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  color: ${colors.$primary2};
  &:hover {
    border-radius: 3px;
  }
`
const CustomPlaceholderContainer = styled.div`
  position: relative;
  width: 100%;
  .placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    position: absolute;
    left: 16px;
    bottom: 16px;
    display: flex;
    div:first-of-type {
      color: #aaafbc;
    }
    div:last-of-type {
      color: ${colors.$secondary};
    }
  }
`

const AliasStatus = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
`

const AliasSuffix = styled.div`
  width: 100%;
  padding: 16px;
  position: absolute;
  color: ${colors.$secondary};
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  .name {
    color: transparent;
  }
`
