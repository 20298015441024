import { object, string, array } from 'yup'

import { isAddressValid } from 'src/utils/address'

export const validationSchema = (isReserve = false) =>
  object().shape({
    ...(!isReserve && {
      walletAddress: string()
        .required('Required field')
        .test('isValidAddress', 'Invalid Ethereum address', (value = '') => isAddressValid(value))
    }),
    aliases: array()
      .of(
        object().shape({
          value: string()
            .required('Required field')
            .min(3, 'Must be at least 3 characters')
            .max(20, 'Must be at most 20 characters')
            .test('invalidSymbols', 'Only numbers and letters are allowed', (value = '') =>
              /^[A-Za-z0-9]*$/g.test(value)
            )
            .test(
              'alreadyAdded',
              'Alias already added',
              (value: string | undefined, { from }: any) => {
                let exists = false
                const aliases = from[1].value.aliases

                aliases.forEach((alias: { value: string }, index: number) => {
                  if (
                    value?.toLowerCase() === alias.value.toLowerCase() &&
                    aliases.findIndex(
                      (e: { value: string }) => e.value.toLowerCase() === value.toLowerCase()
                    ) < index
                  ) {
                    exists = true
                  }
                })
                return !exists
              }
            )
        })
      )
      .min(1, 'Minimum 1 alias')
  })
