import { useStore } from 'effector-react'

import { $user } from 'src/state/store'
import { UserRoles } from 'src/constants'
import { useDomainData } from 'src/hooks/use-domain-data'

interface HaveAccess {
  roles: Array<UserRoles>
  only?: boolean
  onlyNot?: boolean
  inFund?: boolean
}

const rolesPriority = [UserRoles.Moderator, UserRoles.Owner, UserRoles.Admin, UserRoles.SuperAdmin]

export const useUserRole = () => {
  const { id } = useDomainData()
  const user = useStore($user)
  const isDeactivated = user?.deactivated

  const biggestRoleIndex =
    user?.domains?.reduce((acc, domain) => {
      const roleIndex = rolesPriority.indexOf(domain.role)
      if (acc < roleIndex) {
        return roleIndex
      }
      return acc
    }, -1) ?? -1

  const domainRole = user?.domains?.find((domain) => domain.domainId === id)?.role

  const userRole = isDeactivated
    ? UserRoles.User
    : rolesPriority[biggestRoleIndex] || UserRoles.User

  return domainRole || userRole
}

export const useHaveAccess = ({ roles, only = true, onlyNot = false }: HaveAccess) => {
  let isAllowed = false
  let isNotAllowed = true

  const userRole = useUserRole()

  const role = userRole

  if (only) {
    const includes = roles.includes(role)
    isAllowed = includes
    isNotAllowed = !includes
  }

  if (onlyNot) {
    const includes = roles.includes(role)
    isAllowed = !includes
    isNotAllowed = includes
  }

  return { isAllowed, isNotAllowed }
}
