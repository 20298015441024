import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, InputProps as InputPropsTypes, Select, MenuItem, Popper } from '@mui/material'
import styled from '@emotion/styled'
import 'react-datepicker/dist/react-datepicker.css'
import { useFormContext } from 'react-hook-form'
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/system'
import range from 'lodash/range'
import moment from 'moment'

import calendarIcon from 'src/assets/calendar.svg'
import { colors } from 'src/constants/colors'
import { useIsOpen } from 'src/hooks/use-is-open'

import { TextFieldPrimary } from './TextField'
import { ErrorMessage } from './ErrorMessage'
import { RadioButton } from './RadioButton'

interface Props {
  name: string
  label?: string
  value: any
  placeholder?: string
  disabled?: boolean
  type?: string
  inputType?: string
  options?: { label: string; value: any; icon?: string }[]
  style?: React.CSSProperties
  InputProps?: InputPropsTypes
  decimals?: number
  minDate?: Date | string
  id?: string
  inputKey?: string
  error?: string
  labelIcon?: JSX.Element
  afterChange?: (value: any) => void
  valueSuffix?: JSX.Element | string
}

export const FormField = ({
  value,
  placeholder,
  type = 'text',
  inputType = 'text',
  label,
  name,
  disabled,
  options = [],
  style,
  InputProps,
  decimals = 6,
  minDate,
  inputKey,
  error,
  labelIcon,
  afterChange,
  valueSuffix
}: Props) => {
  const {
    register,
    formState: { errors },
    setValue
  } = useFormContext()

  const { isOpen: isDatePickerOpen, open: openDatePicker, close: closeDatePicker } = useIsOpen()

  const errorMessage = error || errors[name]?.message || errors.root?.[name]?.message

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let regexp = new RegExp(`[0-9]*`, 'g')
    if (decimals) {
      regexp = new RegExp(`[0-9]*[.]?[0-9]{0,${decimals}}`, 'g')
    }
    const numberValue = e.target.value.match(regexp)?.[0] || ''

    if (numberValue !== value) {
      setValue(name, numberValue, {
        shouldTouch: true,
        shouldValidate: true,
        shouldDirty: true
      })
    }
  }

  return (
    <FormControl variant="standard" fullWidth style={style} key={inputKey}>
      {label && (
        <Label color={colors.$black} className="form-control-label">
          {label}
          {labelIcon}
        </Label>
      )}
      {type === 'text' && (
        <Box display="flex" alignContent="center" position="relative">
          <TextFieldPrimary
            {...register(name)}
            InputProps={InputProps}
            {...(inputType === 'number' && {
              onChange
            })}
            {...(Boolean(afterChange) && {
              onChange: (event) => {
                register(name).onChange(event)

                if (afterChange) {
                  afterChange(event.target.value)
                }
              }
            })}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            fullWidth
          />
          {valueSuffix}
        </Box>
      )}
      {type === 'radio' && (
        <RadioContainer {...register(name)}>
          {options.map((option) => (
            <RadioItem
              key={option.value}
              onClick={() => {
                if (option.value !== 'value' && !disabled) {
                  setValue(name, option.value, {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true
                  })
                }
              }}
            >
              <RadioButton checked={option.value === value} disabled={disabled} />
              <div className="label">{option.label}</div>
            </RadioItem>
          ))}
        </RadioContainer>
      )}

      {type === 'select' && (
        <Select {...register(name)} disabled={disabled} value={value} variant="outlined">
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Box display="flex" alignItems="center" gap="12px">
                {option.icon && (
                  <img src={option.icon} alt={option.icon} width="20px" height="20px" />
                )}
                <SelectOptionLabel>{option.label}</SelectOptionLabel>
              </Box>
            </MenuItem>
          ))}
        </Select>
      )}
      {type === 'date' && (
        <DateContainer>
          <CalendarIcon src={calendarIcon} alt="calendarIcon" />
          <DatePicker
            {...register(name)}
            autoComplete="off"
            placeholderText={placeholder}
            open={isDatePickerOpen}
            onFocus={() => {
              openDatePicker()
            }}
            locale="en-GB"
            onClickOutside={(e: any) => {
              if (e.target.localName === 'li' || e.target.id.includes('select')) return
              closeDatePicker()
            }}
            minDate={minDate ? new Date(minDate) : null}
            showPopperArrow={false}
            selected={value ? new Date(value) : null}
            onChange={(date) => {
              setValue(name, moment(date).utc(true).toISOString(), {
                shouldTouch: true,
                shouldValidate: true,
                shouldDirty: true
              })
              closeDatePicker()
            }}
            dateFormat="dd MMM yyyy"
            disabled={disabled}
            renderCustomHeader={(props: ReactDatePickerCustomHeaderProps) => (
              <CustomDatePickerHeader {...props} />
            )}
          />
        </DateContainer>
      )}
      <ErrorMessage message={errorMessage as string} isError={Boolean(errorMessage)} />
    </FormControl>
  )
}

const CustomDatePickerHeader = ({
  changeYear,
  changeMonth,
  date,
  ...props
}: ReactDatePickerCustomHeaderProps) => {
  const years = useMemo(
    () => range(new Date().getFullYear(), new Date().getFullYear() + 100, 1),
    []
  )
  const months = useMemo(
    () => [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    []
  )

  const [currMonth, setMonth] = useState(new Date(date).getMonth())
  const [currYear, setYear] = useState(new Date(date).getFullYear())

  return (
    <DatePickerHeader>
      <Select
        disableUnderline
        value={currMonth}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '200px'
            }
          }
        }}
        IconComponent={() => <ExpandMoreIcon sx={{ fill: '#1E2223' }} />}
        onChange={({ target: { value } }) => {
          changeMonth(+value)
          setMonth(+value)
        }}
      >
        {months.map((month, index) => (
          <MenuItem key={month} value={index}>
            <SelectOptionLabel id={`select-month-${month}`}>{month}</SelectOptionLabel>
          </MenuItem>
        ))}
      </Select>
      <Select
        disableUnderline
        value={currYear}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '200px'
            }
          }
        }}
        onChange={({ target: { value } }) => {
          changeYear(+value)
          setYear(+value)
        }}
        IconComponent={() => <ExpandMoreIcon sx={{ fill: '#1E2223' }} />}
      >
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            <SelectOptionLabel id={`select-year-${year}`}>{year}</SelectOptionLabel>
          </MenuItem>
        ))}
      </Select>
    </DatePickerHeader>
  )
}

const DatePickerHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  .MuiSelect-select {
    padding: 0px 12px 0px 0px !important;
  }
`

const Label = styled.div`
  color: ${colors.$secondary};
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const RadioItem = styled.div`
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  .label {
    color: ${colors.$secondary};
    font-size: 16px;
    font-weight: 600;
    line-height: 100%; /* 16px */
  }
`

const SelectOptionLabel = styled.div`
  color: ${colors.$secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 16px; /* 100% */
  width: 100%;
`

const CalendarIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  z-index: 2;
`

const DateContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.$borderSecondary};
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }
  input {
    padding: 13px 16px 13px 52px;
    border: none;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-family: 'Inter';
    ::placeholder {
      color: rgba(0, 0, 0, 0.32);
      font-weight: 400;
      /* color: rgba(0, 0, 0, 0.42); */
    }
  }
  .react-datepicker {
    color: ${colors.$secondary};
    padding: 44px;
    font-family: 'Inter' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  .react-datepicker__header {
    background-color: transparent;
    border: none;
  }

  .react-datepicker__week,
  .react-datepicker__day-names {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 0px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.$secondary};
    :hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day--outside-month {
    color: ${colors.$secondary2};
  }
  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0px;
  }
  .react-datepicker__header {
    padding: 0px;
    margin-bottom: 16px;
  }
  .react-datepicker__day--selected {
    background-color: ${colors.$blue};
    border-radius: 50%;
    color: white;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${colors.$lightBlue};
    border-radius: 50%;
    color: white;
  }
  .react-datepicker__day--disabled {
    color: #ccc !important;
  }
`
