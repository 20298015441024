import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import CopyIcon from '@mui/icons-material/ContentCopyOutlined'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { DomainName } from 'src/components/common'
import { colors } from 'src/constants/colors'
import { CopiedMessageUpdated, CopyIconWrapper } from 'src/components/table'
import { useDomainData } from 'src/hooks/use-domain-data'

export const IntegrationPage = () => {
  const [copied, setCopied] = useState({ script: false, html: false })

  const { name } = useDomainData()

  const onCopy = (name: 'script' | 'html') => {
    setCopied((state) => ({ ...state, [name]: true }))
    setTimeout(() => setCopied((state) => ({ ...state, [name]: false })), 1000)
  }

  return (
    <Container>
      <DomainName />
      <Header>
        <SectionLabel>Integration</SectionLabel>
      </Header>
      <ScriptContainer>
        <div className="label">Use this code to integrate the form</div>
        <div className="script">
          <div>
            {/* <b>index.html</b>
            <br /> */}
            {`
                <script async type="module" id="xype-script" src="https://xype-user.s3.eu-central-1.amazonaws.com/form.js?domain=.${name}"></script>
            `}
            <br />
            {`
                <link rel="stylesheet" href="https://xype-user.s3.eu-central-1.amazonaws.com/style.css"></link>
            `}
          </div>

          <CopyIconWrapper>
            <CopyToClipboard
              onCopy={() => onCopy('script')}
              text={`
                <script async type="module" id="xype-script" src="https://xype-user.s3.eu-central-1.amazonaws.com/form.js?domain=.${name}"></script>
                <link rel="stylesheet" href="https://xype-user.s3.eu-central-1.amazonaws.com/style.css"></link>
            `}
            >
              <CopyIcon sx={{ fill: colors.$successOrange, cursor: 'pointer' }} />
            </CopyToClipboard>
            {copied.script ? (
              <CopiedMessageUpdated className="copied">Copied!</CopiedMessageUpdated>
            ) : (
              ''
            )}
          </CopyIconWrapper>
        </div>
      </ScriptContainer>
      <ScriptContainer>
        <div className="label">Place where you need form</div>
        <div className="script">
          <div>{`<div id="xype-root" />`}</div>

          <CopyIconWrapper>
            <CopyToClipboard onCopy={() => onCopy('html')} text={`<div id="xype-root" />`}>
              <CopyIcon sx={{ fill: colors.$successOrange, cursor: 'pointer' }} />
            </CopyToClipboard>
            {copied.html ? (
              <CopiedMessageUpdated className="copied">Copied!</CopiedMessageUpdated>
            ) : (
              ''
            )}
          </CopyIconWrapper>
        </div>
      </ScriptContainer>
      <ScriptContainer>
        <div className="label">Call next function to initialize the form</div>
        <div className="script">
          <div>{`window.Xype.mount()`}</div>

          <CopyIconWrapper>
            <CopyToClipboard onCopy={() => onCopy('html')} text={`window.Xype.mount()`}>
              <CopyIcon sx={{ fill: colors.$successOrange, cursor: 'pointer' }} />
            </CopyToClipboard>
            {copied.html ? (
              <CopiedMessageUpdated className="copied">Copied!</CopiedMessageUpdated>
            ) : (
              ''
            )}
          </CopyIconWrapper>
        </div>
      </ScriptContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .actions {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
  }
`
const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`

const ScriptContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: ${colors.$primary2};
  .label {
    font-size: 16px;
    font-weight: 700;
  }
  .script {
    padding: 16px;
    background-color: ${colors.$bgLight};
    font-size: 16px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
`
