import React from 'react'
import styled from '@emotion/styled'

import SearchBar from 'src/components/SearchBar'
import { ButtonTransparent, Select } from 'src/components/common'
import { MEDIA_WIDTHS } from 'src/constants'

interface Props {
  setFilters: React.Dispatch<React.SetStateAction<{ search: string; status: string[] }>>
  filters: Record<string, any>
}

const statusOptions = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  },
  {
    label: 'Reserved',
    value: 'reserved'
  },
  {
    label: 'Expired',
    value: 'expired'
  }
]

export const Filters = ({ filters, setFilters }: Props) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value
    setFilters((state) => ({ ...state, search }))
  }

  const handleChangeStatus = (event: any) => {
    const selectedValue: any = event.target.value
    const isAll = selectedValue.includes('all')
    setFilters((state) => ({ ...state, status: isAll ? [] : selectedValue }))
  }
  const onClearFilters = () => {
    setFilters({
      search: '',
      status: []
    })
  }

  return (
    <Container>
      <SearchBar
        name="search"
        value={filters.search}
        onChange={handleSearchChange}
        placeholder="Search by Alias or Wallet"
      />
      <Select
        value={filters.status}
        onChange={handleChangeStatus}
        multiple
        options={statusOptions}
      />
      <ClearFiltersButton onClick={onClearFilters}>Clear</ClearFiltersButton>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  gap: 20px;

  grid-template-columns: 1fr 170px 136px;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    grid-template-columns: 1fr;
  }
`

const ClearFiltersButton = styled(ButtonTransparent)`
  background-color: #1e2223 !important;
  color: white;
  text-transform: none;
  white-space: nowrap;
  padding: 15px 22px !important;
  max-width: 136px;
  width: 100%;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    max-width: 100%;
  }
`
