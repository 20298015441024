import { object, string, array } from 'yup'

import { isAddressValid } from 'src/utils/address'

export const validationSchema = object().shape({
  name: string()
    .required('Required field')
    .min(2, 'Must be at least 2 characters')
    .max(30, 'Must be at most 30 characters'),
  wallet: string()
    .required('Required field')
    .test('isValidAddress', 'Invalid Ethereum address', (value = '') => isAddressValid(value))
})
