import React, { CSSProperties, useMemo } from 'react'

import styled from '@emotion/styled'
import { Box } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { useParams } from 'react-router-dom'
import { useStore } from 'effector-react'

import { colors } from 'src/constants/colors'
import EmptyStateIcon from 'src/assets/empty_state_icon.svg'
import { LoaderContainer } from 'src/components/loading-indicator'
import { Switch } from 'src/components/common'
import { $domainsList } from 'src/state/store'

export const PageTitle = ({ title, style }: { title: string; style?: CSSProperties }) => {
  return <Title style={style}>{title}</Title>
}

const Title = styled.div`
  color: ${colors.$secondary};
  font-size: 30px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.6px;
`

export const EmptyStateBox = ({ message = 'No records found' }: { message?: string }) => (
  <Wrapper>
    <img src={EmptyStateIcon} alt="empty-state-icon" />
    <WrapperMessage>{message}</WrapperMessage>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  height: 181px;
  width: 100%;
  box-sizing: border-box;
  gap: 12px;
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: silver; stroke-width: 2; stroke-dasharray: 8'/></svg>");
  border-radius: 8px;
  align-self: stretch;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-weight: 500;
  font-size: 16px;
  line-height: 138%;

  color: ${colors.$primary2};
`

const WrapperMessage = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 138%;
`
interface HideUnhideSwitchProps {
  checked: boolean
  onChange: (val: boolean) => void
  text?: string
  withIcon?: boolean
  mt?: number
}

export const HideUnhideSwitch = ({
  checked,
  onChange,
  text = 'Show hidden users',
  withIcon = true,
  mt = 3
}: HideUnhideSwitchProps) => (
  <SwitchContainer mt={mt}>
    <Switch
      checked={checked}
      onChange={onChange}
      label={
        <Box display="flex" alignItems="center" justifyContent="start">
          <Box mr={withIcon ? 1 : 0}>{text}</Box>
          {withIcon && (
            <Box display="flex" alignItems="center" justifyContent="start">
              {checked ? (
                <VisibilityOffOutlinedIcon fontSize="small" />
              ) : (
                <VisibilityOutlinedIcon fontSize="small" />
              )}
            </Box>
          )}
        </Box>
      }
      type="primary"
    />
  </SwitchContainer>
)

const SwitchContainer = styled(Box)`
  background-color: rgba(212, 219, 231, 0.16);
  padding: 10px;
`

export const DomainName = () => {
  const { domain = '' } = useParams<{ domain?: string }>()

  return <Name>{domain}</Name>
}

const Name = styled.div`
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.6px;
  line-height: 30px;
`

export const CopiedMessage = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;
  background: ${colors.$primaryLight};
  color: ${colors.$white};
  border-radius: 10px;
  padding: 5px 7px;
  font-size: 12px;
  font-weight: 700;
  z-index: 1;
`
