import React, { useState } from 'react'
import { css, TableContainer, Button } from '@mui/material'
import moment from 'moment'

import { IAdmin } from 'src/types'
import { colors } from 'src/constants/colors'
import { Table, HeaderRow, BodyRow, CellPrimary } from 'src/components/table'

import { AddressesCell } from 'src/components/table/addresses-cell'

import { AdminModal } from './admin-modal'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/constants'
import styled from '@emotion/styled'
import useMetaMask from 'src/hooks/useMetaMask'

const headerData: string[] = ['Name', 'Wallet address', 'Role', 'Registration', '']

const roleText = {
  [UserRoles.Admin]: 'Admin',
  [UserRoles.SuperAdmin]: 'Super Admin'
} as Record<UserRoles, string>

interface Props {
  items: IAdmin[]
  fetchAdmins: () => Promise<void>
}

export const AdminsTable = ({ items, fetchAdmins }: Props) => {
  const [editData, setEditData] = useState<IAdmin | null>(null)

  const { isAllowed: isSuperAdmin } = useHaveAccess({ roles: [UserRoles.SuperAdmin] })
  const { account } = useMetaMask()

  const closeModal = (needRefresh = false) => {
    if (needRefresh) {
      fetchAdmins()
    }
    setEditData(null)
  }

  const onEdit = (user: IAdmin) => {
    setEditData(user)
  }

  return (
    <>
      {Boolean(editData) && <AdminModal initialValues={editData} handleClose={closeModal} />}
      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: '575px' }}>
          <HeaderRow rowTemplate={rowTemplate}>
            {headerData.map((header) => (
              <CellPrimary key={header}>{header}</CellPrimary>
            ))}
          </HeaderRow>
          <>
            {items.map((row, idx) => (
              <StyledBodyRow key={row.id} rowTemplate={rowTemplate} deactivated={row.deactivated}>
                <CellPrimary>{row.name}</CellPrimary>
                <CellPrimary>
                  <AddressesCell address={row.user.ethAddress} id={`${idx}_owner`} />
                </CellPrimary>
                <CellPrimary>{roleText[row.role] || row.role}</CellPrimary>
                <CellPrimary>{moment(row.createdAt).utc().format('DD MMM YYYY')}</CellPrimary>

                <CellPrimary>
                  <Button
                    disabled={
                      !isSuperAdmin || account?.toLowerCase() === row.user.ethAddress.toLowerCase()
                    }
                    onClick={() => isSuperAdmin && onEdit(row)}
                    color="info"
                    sx={{
                      textTransform: 'none',
                      textDecoration: 'underline',
                      fontWeight: 500,
                      fontSize: '16px',
                      marginTop: '-4px',
                      color: colors.$darkBlue
                    }}
                  >
                    Edit
                  </Button>
                </CellPrimary>
              </StyledBodyRow>
            ))}
          </>
        </Table>
      </TableContainer>
    </>
  )
}

const rowTemplate = css`
  grid-template-columns: repeat(4, 1fr) 100px;

  a {
    text-decoration: underline;
  }
`

const StyledBodyRow = styled(BodyRow)<{ deactivated: boolean }>`
  ${({ deactivated }) =>
    deactivated &&
    css`
      *:not(svg, path, .copied, button) {
        color: ${colors.$primaryLight} !important;
      }
    `};
`
