export const metamask = {
  challenge: `metamask/challenge`,
  login: `metamask/login`,
  hasLogged: (hash: string) => `/metamask/hasLogged/${hash}`
}

export const unauthorizedUrls = [
  '/auth/me',
  '/health',
  '/getLastMaintenanceState',
  '/dangerous/maintenance',
  metamask.challenge,
  metamask.login
]
