import { useState } from 'react'

export const useIsOpen = (defaultValue = false) => {
  const [isOpen, handleIsOpen] = useState(defaultValue)

  const open = () => handleIsOpen(true)
  const close = () => handleIsOpen(false)
  const toggle = () => handleIsOpen((state) => !state)

  return {
    isOpen,
    open,
    close,
    toggle
  }
}
