import { useStore } from 'effector-react'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { $domainsList, setDomain } from 'src/state/store'
import { $domain } from 'src/state/store'
import { Domain } from 'src/types'
import { useRegistrarContract } from './useContracts'

interface DomainData extends Domain {
  urlName: string
  isPaused: boolean
  isDeactivated: boolean
}

export const useDomainData = () => {
  const domain = useStore($domain)
  const { domain: domainName } = useParams<{ domain?: string }>()
  const domainsList = useStore($domainsList)

  const [isPaused, setIsPaused] = useState(false)
  const [isDeactivated, setIsDeactivated] = useState(false)

  const domainData = useMemo(
    () => domainsList.find(({ name }) => name === domainName),
    [domainsList, domainName]
  )

  const registrarContract = useRegistrarContract(domain?.contractAddress)

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const [paused, deactivated] = await Promise.all([
          registrarContract.isRegistrationPaused(),
          registrarContract.isDomainPaused()
        ])
        setIsPaused(paused)
        setIsDeactivated(deactivated)
      } catch (error) {}
    }
    if (registrarContract.target) {
      fetchStatuses()
    }
  }, [registrarContract.target])

  useEffect(() => {
    if (domainData?.id && domain?.id && domainData?.id !== domain?.id) {
      // setDomain(null)
      setIsPaused(false)
      setIsDeactivated(false)
    }
  }, [domainData?.id, domain?.id])

  return (
    domainData?.id
      ? {
          ...domain,
          id: domainData?.id,
          urlName: `.${domain?.name}`,
          // isPaused,
          // isDeactivated
          isPaused: domain?.status === 'paused',
          isDeactivated: domain?.status === 'deactivated'
        }
      : {}
  ) as DomainData
}
