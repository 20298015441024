import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import AddIcon from '@mui/icons-material/Add'

import { ButtonPrimary, Switch, DomainName, EmptyStateBox } from 'src/components/common'
import { TablePagination } from 'src/components/table/pagination'
import { useIsOpen } from 'src/hooks/use-is-open'
import { Moderator, ResponseWithPagination } from 'src/types'

import { Filters } from './filters'
import { ModeratorsTable } from './moderators-table'
import { ModeratorModal } from './moderator-modal'
import apiService from 'src/services/api'
import { useDomainData } from 'src/hooks/use-domain-data'
import LoadingIndicator from 'src/components/loading-indicator'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/constants'

export const ModeratorsPage = () => {
  const [moderators, setModerators] = useState<ResponseWithPagination<Moderator> | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [editData, setEditData] = useState<Moderator | null>(null)
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<{ search: string }>({
    search: ''
  })
  const [showPastModerators, setShowPastModerators] = useState(false)
  const timeout = useRef(0 as any)

  const { isAllowed } = useHaveAccess({
    roles: [UserRoles.Admin, UserRoles.Moderator],
    onlyNot: true
  })

  const { id, isDeactivated, status } = useDomainData()
  const { isOpen: isAddOpen, close: closeAdd, open: openAdd } = useIsOpen()

  const disableActions = useMemo(
    () => isDeactivated || ['deactivated', 'inactive', 'expired'].includes(status),
    [status, isDeactivated]
  )

  const fetchModerators = async () => {
    try {
      setIsLoading(true)
      const res = await apiService.getModerators({
        id,
        offset: 10,
        page,
        ...filters,
        showPastModerators
      })
      setModerators(res.data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setModerators(null)
    setFilters({ search: '' })
    setPage(1)
    setEditData(null)
    setShowPastModerators(false)
  }, [id])

  useEffect(() => {
    setPage(1)
  }, [filters])

  useEffect(() => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(fetchModerators, 250)
  }, [filters, showPastModerators, page])

  const onCloseModal = (needRefresh = false) => {
    if (needRefresh) {
      fetchModerators()
    }
    if (isAddOpen) {
      closeAdd()
    } else {
      setEditData(null)
    }
  }

  if (!moderators) {
    return <LoadingIndicator />
  }

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      {(isAddOpen || editData) && (
        <ModeratorModal initialValues={editData} handleClose={onCloseModal} />
      )}
      <DomainName />
      <Header>
        <SectionLabel>Moderators</SectionLabel>
        {isAllowed && (
          <div className="actions">
            <ButtonPrimary
              onClick={openAdd}
              width="202px"
              endIcon={<AddIcon sx={{ width: '20px' }} />}
              disabled={disableActions}
            >
              Add Moderator
            </ButtonPrimary>
          </div>
        )}
      </Header>
      <Switch
        checked={showPastModerators}
        onChange={setShowPastModerators}
        label="Show past Moderators"
        type="primary"
      />
      <Filters filters={filters} setFilters={setFilters} />
      {moderators.items.length ? (
        <>
          <ModeratorsTable items={moderators.items} setEditData={setEditData} />
          <TablePagination
            page={page}
            handleChange={(e, newPage) => setPage(newPage)}
            totalItems={moderators.totalItems}
            totalPages={moderators.totalPages}
          />
        </>
      ) : (
        <EmptyStateBox />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .actions {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
  }
`
const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`
