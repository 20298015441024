import { object, string } from 'yup'

import { isAddressValid } from 'src/utils/address'

export const validationSchema = object().shape({
  name: string()
    .required('Required field')
    .min(3, 'Must be at least 3 characters')
    .max(10, 'Must be at most 10 characters')
    .test('invalidFormat', 'Only dot and letters are allowed', (value = '') =>
      /^.[a-zA-Z]{2,9}$/g.test(value)
    ),
  url: string().required('Required field').url('Invalid URL'),
  companyName: string()
    .required('Required field')
    .min(3, 'Must be at least 3 characters')
    .max(30, 'Must be at most 30 characters'),
  ownerName: string()
    .required('Required field')
    .min(3, 'Must be at least 3 characters')
    .max(30, 'Must be at most 30 characters'),
  ownerWallet: string()
    .required('Required field')
    .test('isValidAddress', 'Invalid Ethereum address', (value = '') => isAddressValid(value)),
  expiresAt: string().required('Required field'),
  limit: string()
    .required('Required field')
    .test('min', 'Must be greater than 0', (value = '0') => {
      if (value === '-') return true
      return +value > 0
    }),
  subscription: string()
    .test('isRequired', 'Required field', (value, { parent }) => {
      if (parent.type === 'subscription') return Boolean(value)
      return true
    })
    .nullable(),
  treasuryAddress: string()
    .test('isRequired', 'Required field', (value, { parent }) => {
      if (parent.type !== 'free') return Boolean(value)
      return true
    })
    .test('isValidAddress', 'Invalid Ethereum address', (value, { parent }) => {
      if (parent.type !== 'free') return isAddressValid(value || '')
      return true
    }),
  serviceFee: string()
    .test('isRequired', 'Required field', (value, { parent }) => {
      if (parent.type !== 'free') return Boolean(value)
      return true
    })
    .test('max', 'Must be less than 100', (value, { parent }) => {
      if (parent.type !== 'free') return value !== '-' ? +(value || 0) < 100 : true
      return true
    })
    .test('min', 'Must be greater than 0', (value, { parent }) => {
      if (parent.type !== 'free') return value !== '-' ? +(value || 0) > 0 : true
      return true
    })
    .test('invalidFormat', 'Only integer numbers are allowed', (value, { parent }) => {
      if (parent.type !== 'free') return value && value !== '-' ? /^[0-9]{1,2}$/g.test(value) : true
      return true
    })
    .nullable(),
  priceOfStandart: string().test('isRequired', 'Required field', (value, { parent }) => {
    if (parent.type !== 'free') return Boolean(value)
    return true
  }),
  currency: string().test('isRequired', 'Required field', (value, { parent }) => {
    if (parent.type !== 'free') return Boolean(value)
    return true
  })
})
