import React from 'react'
import styled from '@emotion/styled'

import MenuIcon from '@mui/icons-material/Menu'
import { css, useMediaQuery } from '@mui/material'

import { setIsShowNavigation } from 'src/state/store'

import { AddressDropdown } from './address-dropdown'

export const Header: React.FC = () => {
  const isLaptop = useMediaQuery('@media(min-width: 1000px)')

  const handleShow = () => setIsShowNavigation(true)

  return (
    <HeaderWrapper mobile={!isLaptop}>
      {!isLaptop ? (
        <MenuIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={handleShow} />
      ) : null}
      <AddressDropdown />
    </HeaderWrapper>
  )
}

interface StylesProps {
  mobile: boolean
}

const HeaderWrapper = styled('div')<StylesProps>`
  display: flex;
  justify-content: ${({ mobile }) => (mobile ? 'space-between' : 'flex-end')};
  align-items: center;
  padding: ${({ mobile }) => (mobile ? '0 16px' : '24px 44px 0px')};
  ${({ mobile }) =>
    mobile &&
    css`
      margin-bottom: 32px;
    `}
  height: 88px;
  border-bottom: ${({ mobile }) => (mobile ? '0.5px solid #c9c7c7' : '1px solid white')};
`
