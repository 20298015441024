import { css, TableContainer, Box, Button } from '@mui/material'
import moment from 'moment'

import { Moderator } from 'src/types'
import { colors } from 'src/constants/colors'
import { Table, HeaderRow, BodyRow, CellPrimary } from 'src/components/table'

import { AddressesCell } from 'src/components/table/addresses-cell'
import { UserRoles } from 'src/constants'
import { useHaveAccess } from 'src/hooks/useAccessController'
import styled from '@emotion/styled'

const headerData: string[] = ['Name', 'Wallet address', 'Registration', '']

interface Props {
  setEditData: (value: Moderator | null) => void
  items: Moderator[]
}

export const ModeratorsTable = ({ items, setEditData }: Props) => {
  const { isAllowed } = useHaveAccess({
    roles: [UserRoles.Admin, UserRoles.Moderator],
    onlyNot: true
  })

  const onEdit = (user: Moderator) => {
    setEditData(user)
  }

  return (
    <>
      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: '750px' }}>
          <HeaderRow rowTemplate={rowTemplate}>
            {headerData.map((header) => (
              <CellPrimary key={header}>{header}</CellPrimary>
            ))}
          </HeaderRow>
          <>
            {items.map((row, idx) => (
              <StyledBodyRow key={row.id} rowTemplate={rowTemplate} deactivated={row.deactivated}>
                <CellPrimary>{row.name}</CellPrimary>
                <CellPrimary>
                  <AddressesCell address={row.user.ethAddress} id={`${idx}_owner`} />
                </CellPrimary>

                <CellPrimary>{moment(row.createdAt).utc().format('DD MMM YYYY')}</CellPrimary>

                <CellPrimary>
                  <Button
                    disabled={!isAllowed}
                    onClick={() => isAllowed && onEdit(row)}
                    color="info"
                    sx={{
                      textTransform: 'none',
                      textDecoration: 'underline',
                      fontWeight: 500,
                      fontSize: '16px',
                      marginTop: '-4px',
                      color: colors.$darkBlue
                    }}
                  >
                    Edit
                  </Button>
                </CellPrimary>
              </StyledBodyRow>
            ))}
          </>
        </Table>
      </TableContainer>
    </>
  )
}

const rowTemplate = css`
  grid-template-columns: 1fr 1fr 1fr 100px;
`

const StyledBodyRow = styled(BodyRow)<{ deactivated: boolean }>`
  ${({ deactivated }) =>
    deactivated &&
    css`
      *:not(svg, path, .copied, button) {
        color: ${colors.$primaryLight} !important;
      }
    `};
`
