import React, { Suspense, useEffect, useLayoutEffect, useMemo } from 'react'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { Box } from '@mui/system'
import { useMediaQuery } from '@mui/material'
import { useStore } from 'effector-react'

import { useHaveAccess } from 'src/hooks/useAccessController'
import useMetaMask from 'src/hooks/useMetaMask'
import {
  $auth,
  $domainsList,
  $isMaintenanceModeActive,
  $user,
  $isUserDeactivated,
  setDomain
} from 'src/state/store'
import { UserRoles } from 'src/constants'
import { useDomainData } from 'src/hooks/use-domain-data'

import { Navigation } from '../navigation'
import { Header } from '../header/admin-header'
import LoadingIndicator from '../loading-indicator'
import { AdminLayoutWrapper, BoxWrapper, ContentWrapper } from './styles'
import apiService from 'src/services/api'

const AdminLayout: React.FC = () => {
  const { isNotAllowed } = useHaveAccess({ roles: [UserRoles.User], onlyNot: true })
  const { account, isLoading } = useMetaMask()
  const auth = useStore($auth)
  const user = useStore($user)
  const isUserDeactivated = useStore($isUserDeactivated)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)
  const { id, contractAddress } = useDomainData()
  const domainsList = useStore($domainsList)
  const { domain: domainName = '' } = useParams()
  const isLaptop = useMediaQuery('@media(min-width: 1000px)')

  const location = useLocation()

  useLayoutEffect(() => {
    // Destroy support beacon for Admin panel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window?.Beacon) window.Beacon!('destroy')
  }, [location.pathname])

  useEffect(() => {
    const fetchDomainData = async () => {
      try {
        const res = await apiService.getDomain(id)

        setDomain(res.data)
      } catch (error) {}
    }
    if (id) {
      fetchDomainData()
    }
  }, [id])

  const needRedirect = useMemo(() => {
    if (isLoading) return false

    if (auth?.[account]) {
      if (user && (isUserDeactivated || isNotAllowed)) {
        return true
      }
      return false
    }
    return true
  }, [auth, account, user, isUserDeactivated, isNotAllowed, isLoading])

  const needRedirectFromDomain = useMemo(() => {
    if (isLoading) return false

    if (user && user.role !== UserRoles.SuperAdmin) {
      if (
        domainName &&
        domainsList.length &&
        domainsList.every((domain) => domain.name !== domainName)
      ) {
        return true
      }
      return false
    }
    return false
  }, [auth, account, user, isLoading, domainName, domainsList])

  if (needRedirect) {
    return <Navigate to="/" replace={true} />
  }

  if (needRedirectFromDomain) {
    let to = '/domains'
    if (domainsList.length) {
      to = `/domain/${domainsList[0].name}/aliases`
    }
    return <Navigate to={to} />
  }

  // Destroy support beacon for Admin panel
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window?.Beacon) window.Beacon('destroy')

  if (isNotAllowed || (domainName ? !contractAddress : false)) {
    return <LoadingIndicator />
  }

  const withoutMaxWidth = location.pathname.includes('manual')

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Box position="relative" sx={{ paddingTop: isMaintenanceModeActive ? '37px' : 0 }}>
        <AdminLayoutWrapper isMaintenanceModeActive={isMaintenanceModeActive}>
          <Navigation />

          <BoxWrapper
            mobile={!isLaptop}
            isMaintenanceModeActive={isMaintenanceModeActive}
            withoutMaxWidth={withoutMaxWidth}
            className="hide-scroll"
          >
            <Header />

            <ContentWrapper mobile={!isLaptop}>
              <Outlet />
            </ContentWrapper>
          </BoxWrapper>
        </AdminLayoutWrapper>
      </Box>
    </Suspense>
  )
}

export default AdminLayout
