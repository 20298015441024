import { styled, TextField } from '@mui/material'
import { colors } from '../../../constants/colors'

export const TextFieldPrimary = styled(TextField)(() => ({
  ['& input']: {
    fontSize: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    paddingRight: 30,
    borderRadius: '4px'
  },
  ['& .Mui-disabled']: {
    backgroundColor: colors.$disabledBg3,
    color: colors.$primaryLight
    // paddingRight: 45
  },
  ['& input.Mui-disabled ~ fieldset']: {
    color: colors.$primaryLight
  }
}))
