import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import isEqual from 'lodash/isEqual'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import apiService from 'src/services/api'
import { ResponseWithPagination, ILog } from 'src/types'
import LoadingIndicator from 'src/components/loading-indicator'
import { useDomainData } from 'src/hooks/use-domain-data'
import { EmptyStateBox, PageTitle, DomainName } from 'src/components/common'
import { TablePagination } from 'src/components/table/pagination'

import { LogsTable } from './logs-table'
import { Filters } from './filters'

const OFFSET_VALUE = 10
let timeoutId = null as any

export const LogsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({
    teamWallet: '',
    actionWallet: '',
    actions: []
  })

  const [logs, setLogs] = useState<ResponseWithPagination<ILog> | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const { domain = '' } = useParams<{ domain?: string }>()
  const { id } = useDomainData()

  useEffect(() => {
    setLogs(null)
    setFilters({
      teamWallet: '',
      actionWallet: '',
      actions: []
    })
  }, [id])

  const getLogs = (page = 1) => {
    if (domain && !id) return

    const logsParams = {
      page,
      offset: OFFSET_VALUE,
      teamWallet: filters.teamWallet.trim(),
      ...(id
        ? {
            aliasOrWallet: filters.actionWallet.trim()
          }
        : { actionWallet: filters.actionWallet.trim() }),
      ...(filters.actions.length && {
        actionTypes: filters.actions.join(',')
      })
    }

    setIsLoading(true)
    if (id) {
      apiService
        .getDomainLogs({ id, ...logsParams })
        .then((response) => {
          const logsData: ResponseWithPagination<ILog> = response?.data
          setLogs(logsData)
        })
        .catch(() => {
          setLogs(null)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      apiService
        .getLogs(logsParams)
        .then((response) => {
          const logsData: ResponseWithPagination<ILog> = response?.data
          setLogs(logsData)
        })
        .catch(() => {
          setLogs(null)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => getLogs(1), 250)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [filters, id])

  const onFiltersChange = (data: Record<string, any>) => {
    setFilters(data)
  }

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    getLogs(value)
  }

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      {domain && <DomainName />}

      <PageTitle
        title="Logs"
        {...(domain && {
          style: { fontSize: '20px' }
        })}
      />
      <Filters filters={filters} setFilters={onFiltersChange} />
      {logs ? (
        <>
          {logs?.items?.length ? (
            <>
              <Box>
                <LogsTable data={logs.items} />
              </Box>
              <TablePagination
                totalItems={logs.totalItems}
                totalPages={logs.totalPages}
                handleChange={handleChangePagination}
                page={logs.page}
              />
            </>
          ) : (
            <EmptyStateBox message="No records found" />
          )}
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
