import React from 'react'
import styled from '@emotion/styled'

import logoIcon from 'src/assets/logo.svg'

import { colors } from 'src/constants/colors'

interface LogoProp {
  logoView?: string
  padding?: string
  width?: number | string
  size?: string
}

export const AppLogo = ({ logoView = 'light', size, ...props }: LogoProp) => {
  return (
    <Logo logoView={logoView}>
      <img src={logoIcon} alt="logoIcon" />
      xype
    </Logo>
  )
}

const Logo = styled.div<{ logoView: string }>`
  color: ${colors.$main};
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
`
