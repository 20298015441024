import { useCallback } from 'react'
import { useStore } from 'effector-react'
import moment from 'moment'

import { $auth, setAuth, triggerSignatureEffect } from 'src/state/store'

import apiService from '../services/api'
import { login } from './login'
import { sign } from './personalSign'
import useMetaMask from '../hooks/useMetaMask'

// const {}

export const useFetchToken = () => {
  const { account, provider } = useMetaMask()
  // const [dataHash, setDataHash] = useState('');
  const auth = useStore($auth)
  const currentDate = moment()
  const futureMonth = moment(currentDate).add(30, 'days')
  const shouldLogoutDate = moment(futureMonth).subtract(1, 'days')

  // const fetchToken = useCallback(async () => {
  //   try {
  //     if (!account) {
  //       throw new Error("User didn't connect a wallet")
  //     }
  //     const { data } = await apiService.sendMetamaskAddress(account)
  //     if (!data.hash) {
  //       throw new Error('No hash received')
  //     }
  //     const result = await sign({ hash: data.hash, account, provider })
  //     if (!result) {
  //       await disconnect()
  //       throw new Error('Login sign failed')
  //     }
  //     const loginData = await login({ hash: result, account })
  //     if (!loginData) {
  //       throw new Error('No login data received')
  //     }
  //     const res = loginData.data;
  //     setIsAuth(true);
  //     const authData = {
  //       ...auth,
  //       [account]: {
  //         ...res,
  //         logoutTime: shouldLogoutDate
  //       },
  //     };
  //     setAuth(authData)
  //     // window.localStorage.setItem("auth", JSON.stringify(authData));
  //   } catch (error) {
  //     setIsAuth(false);
  //   }
  // }, [account, auth])

  // const fetchToken = useCallback(async () => {
  //   const parsedHashes = (window as any).localStorage.getItem('dataHash') && JSON.parse((window as any).localStorage.getItem('dataHash'));

  //   try {
  //     if (!account) {
  //       throw new Error("User didn't connect a wallet")
  //     }
  //     const { data } = await apiService.sendMetamaskAddress(account)
  //     if (!data.hash) {
  //       throw new Error('No hash received')
  //     }

  //     if (!parsedHashes?.[account]) {
  //       window.localStorage.setItem('dataHash', JSON.stringify({[account]: data.hash}))
  //     }

  //   } catch (error) {
  //     setIsAuth(false);
  //   }
  // }, [account])

  const fetchAuth = useCallback(async () => {
    const parsedHashes =
      (window as any).localStorage.getItem('dataHash') &&
      JSON.parse((window as any).localStorage.getItem('dataHash'))

    try {
      if (!account) {
        throw new Error("User didn't connect a wallet")
      }
      const { data } = await apiService.sendMetamaskAddress(account)
      if (!data.hash) {
        throw new Error('No hash received')
      }

      if (!parsedHashes?.[account]) {
        window.localStorage.setItem('dataHash', JSON.stringify({ [account]: data.hash }))
      }

      triggerSignatureEffect(true)

      const result = await sign({ hash: data.hash, account, provider })
      if (!result) {
        triggerSignatureEffect(false)
        ;(window as any).localStorage.removeItem('dataHash')
        // await disconnect()
        throw new Error('Login sign failed')
      }
      const loginData = await login({ hash: result, account })
      if (!loginData) {
        triggerSignatureEffect(false)
        ;(window as any).localStorage.removeItem('dataHash')
        throw new Error('No login data received')
      }
      const res = loginData.data
      // setIsAuth(true)
      const authData = {
        ...auth,
        [account]: {
          ...res,
          logoutTime: shouldLogoutDate
        }
      }
      setAuth(authData)
      triggerSignatureEffect(false)
      ;(window as any).localStorage.removeItem('dataHash')
    } catch (error) {
      // setIsAuth(false)
      triggerSignatureEffect(false)
      ;(window as any).localStorage.removeItem('dataHash')
    }
  }, [auth, account, provider, shouldLogoutDate])

  return { fetchAuth }
}
