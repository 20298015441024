import { createTheme } from '@mui/material/styles'

import { colors } from 'src/constants/colors'

const MuiTheme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTab-root:not(.Mui-selected)': {
            fontWeight: 400
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingTop: '16px !important',
          paddingBottom: '16px !important',
          height: '16px !important',
          minHeight: '16px !important'
          // WebkitTransitionDelay: '99999s'
        },
        root: {
          borderRadius: '0px !important'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: `${colors.$borderSecondary} !important`
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#262730',
      light: '',
      dark: '',
      contrastText: ''
    },
    secondary: {
      main: '#723BE5',
      light: '',
      dark: '',
      contrastText: ''
    },
    error: {
      main: '#E55252',
      light: '',
      dark: '',
      contrastText: ''
    },
    success: {
      main: '#0FD884',
      light: '',
      dark: '',
      contrastText: ''
    },
    footer: '#fff'
  } as any,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1283,
      xl: 1648
    }
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    subtitle1: {
      fontSize: '24px',
      fontWeight: 700
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 300
    },
    body1: {},
    body2: {},
    h6: {
      fontSize: '12px'
    },
    h5: {
      fontSize: '14px'
    },
    h4: {
      fontSize: '16px'
    },
    h3: {
      fontSize: '18px'
    },
    h2: {
      fontSize: '20px'
    },
    h1: {
      fontSize: '22px'
    },
    button: {
      fontWeight: 400
    }
  }
})

export default MuiTheme
