import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import AddIcon from '@mui/icons-material/Add'

import { ButtonPrimary, EmptyStateBox, PageTitle, Switch } from 'src/components/common'
import { TablePagination } from 'src/components/table/pagination'
import { useIsOpen } from 'src/hooks/use-is-open'

import { Filters } from './filters'
import { AdminsTable } from './admins-table'
import { AdminModal } from './admin-modal'
import { IAdmin, ResponseWithPagination } from 'src/types'
import LoadingIndicator from 'src/components/loading-indicator'
import apiService from 'src/services/api'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/constants'

export const AdminsPage = () => {
  const [admins, setAdmins] = useState<ResponseWithPagination<IAdmin> | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [showPastAdmins, setShowPastAdmins] = useState(false)
  const [filters, setFilters] = useState<{ search: string; roles: string[] }>({
    search: '',
    roles: []
  })

  const { isAllowed: isSuperAdmin } = useHaveAccess({ roles: [UserRoles.SuperAdmin] })

  const timeout = useRef(0 as any)

  const { isOpen: isAddOpen, close: closeAdd, open: openAdd } = useIsOpen()

  const fetchAdmins = async () => {
    try {
      setIsLoading(true)
      const res = await apiService.getAdmins({
        page,
        offset: 10,
        ...filters,
        showPastTeamMembers: showPastAdmins,
        roles: filters.roles.length ? filters.roles.join(',') : 'admin,superAdmin'
      })
      setAdmins(res.data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setPage(1)
  }, [filters])

  useEffect(() => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(fetchAdmins, 250)
  }, [filters, page, showPastAdmins])

  const onCloseModal = (needRefresh = false) => {
    if (needRefresh) {
      fetchAdmins()
    }
    closeAdd()
  }

  if (!admins) {
    return <LoadingIndicator />
  }

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      {isAddOpen && <AdminModal initialValues={null} handleClose={onCloseModal} />}
      <Header>
        <PageTitle title="Admins" />
        {isSuperAdmin && (
          <ButtonPrimary
            onClick={openAdd}
            width="202px"
            endIcon={<AddIcon sx={{ width: '20px' }} />}
          >
            Add Admin
          </ButtonPrimary>
        )}
      </Header>
      <Switch
        checked={showPastAdmins}
        onChange={setShowPastAdmins}
        label="Show past Admins"
        type="primary"
      />
      <Filters filters={filters} setFilters={setFilters} />
      {admins.items.length ? (
        <>
          <AdminsTable items={admins.items} fetchAdmins={fetchAdmins} />
          <TablePagination
            page={page}
            handleChange={(e, newPage) => setPage(newPage)}
            totalItems={admins.totalItems}
            totalPages={admins.totalPages}
          />
        </>
      ) : (
        <EmptyStateBox />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`
