import axios from 'axios'

import { setAuth } from 'src/state/store'

import { API_URL } from '../config'
import { metamask, unauthorizedUrls } from './apiUrls'
import { Login, MetamaskChallenge } from './types'
import { PaginationTypes } from '../types'
import { responseRejectInterceptor } from './interceptors'

const getHeaders = (): Record<string, any> => {
  const auth = JSON.parse((window as any).localStorage.getItem('auth'))

  const address = (window as any).localStorage.getItem('walletAddr')
  if (auth?.[address]) {
    return { Authorization: `Bearer ${auth?.[address].accessToken}` }
  } else {
    if (auth && Object.keys(auth).length) {
      delete auth?.[address]
      setAuth(auth)
    }
  }

  return {}
}

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const headers = getHeaders()

    const withAuthorization = !unauthorizedUrls.find((url) =>
      config?.url ? config?.url?.includes(url) || url.includes(config?.url) : true
    )

    if (withAuthorization && !headers.Authorization) {
      return Promise.reject(new Error('Missing Authorization'))
    }

    config = {
      ...config,
      headers
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.request.use((config) => {
  const baseURL = API_URL

  // development env
  // const baseURL = 'https://api-dev.xype.com/v1/'

  // prod
  // const baseURL = 'https://api.xype.com/v1/'

  return { ...config, baseURL }
})

axios.interceptors.response.use((res) => res, responseRejectInterceptor)

const sendMetamaskAddress = (address: string) => {
  return axios.post<MetamaskChallenge>(metamask.challenge, { address })
}

const login = (address: string, hash: string) => {
  return axios.post<Login>(metamask.login, { address, hash })
}

const isBEHealth = () => {
  return axios.get('health')
}

const getLastMaintenanceState = () => {
  return axios.get('getLastMaintenanceState')
}

const switchMaintenanceMode = (enabled: boolean) => {
  const params = {
    enabled
  }
  return axios.post(`dangerous/maintenance?enabled=${enabled}`, params)
}

const getUser = () => {
  return axios.get(`auth/me`)
}

// history
const getLogs = (params: GetLogsParams) => {
  return axios.get(`history/logs`, { params })
}

export const refreshAuthToken = (refreshToken: string) => {
  return axios.post(`${API_URL}/auth/refresh`, {
    refreshToken
  })
}

const getMyDomainsList = () => {
  return axios.get('/domains/list')
}

interface GetDomains {
  search?: string
  searchByOwner?: string
  status?: string
  page?: number
  offset: number
}
const getDomains = (params: GetDomains) => {
  return axios.get('/domains', { params })
}

const getDomain = (id: number) => {
  return axios.get(`/domains/${id}`)
}

const addDomain = (data: Record<string, any>) => {
  return axios.post('/domains', data)
}

const editDomain = ({ id, ...data }: Record<string, any>) => {
  return axios.put(`/domains/${id}`, data)
}

interface DomainAction {
  id: number
  status: string
  actions: string
}

const domainAction = ({ id, ...data }: DomainAction) => {
  return axios.post(`/domains/${id}/action`, data)
}

interface GetAdmins {
  search?: string
  page?: number
  offset: number
  roles?: string
  showPastTeamMembers?: boolean
}
const getAdmins = (params: GetAdmins) => {
  return axios.get('/domains/team', { params })
}

interface EditAdmin {
  id: number
  name: string
  deactivated: boolean
}

const editAdmin = ({ id, ...data }: EditAdmin) => {
  return axios.put(`/domains/team/${id}`, data)
}

interface AddAdmin {
  name: string
  walletAddress: string
  deactivated: boolean
  role: string
  domains?: number[]
}
const addAdmin = (data: AddAdmin) => {
  return axios.post(`/domains/team`, data)
}

interface GetAliases {
  id: number
  search?: string
  page?: number
  offset: number
  status?: string
}

const getAliases = ({ id, ...params }: GetAliases) => {
  return axios.get(`/domains/${id}/aliases`, { params })
}

interface CreateAlias {
  id: number
  wallet: string
  digest: string
  aliases: {
    label: string
    duration: number
    isPrimary: boolean
  }[]
}

const addAlias = ({ id, ...data }: CreateAlias) => {
  return axios.post(`domains/${id}/aliases`, data)
}

interface ReserveAlias {
  id: number
  aliases: string[]
}

const reserveAlias = ({ id, ...data }: ReserveAlias) => {
  return axios.post(`domains/${id}/aliases/reserve`, data)
}

const deleteReservedAlias = (id: string) => {
  return axios.delete(`/domains/aliases/reserved/${id}`)
}

const existAlias = (id: number, name: string) => {
  return axios.get(`/domains/${id}/aliases/reserved/${name}`)
}

interface GetModerators {
  id: number
  search?: string
  page?: number
  offset: number
  roles?: string
  showPastModerators?: boolean
}

const getModerators = ({ id, ...params }: GetModerators) => {
  return axios.get(`/domains/${id}/moderators`, { params })
}

interface AddModerator {
  id: number
  name: string
  wallet: string
}

const addModerator = ({ id, ...data }: AddModerator) => {
  return axios.post(`/domains/${id}/moderators`, data)
}

interface EditModerator {
  id: number
  name: string
  deactivated: boolean
}

const editModerator = ({ id, ...data }: EditModerator) => {
  return axios.put(`/domains/moderators/${id}`, data)
}

const getStopWords = (id: number, search: string) => {
  return axios.get(`/domains/${id}/stop-words`, { params: { search } })
}

const saveStopWords = (id: number, words: string) => {
  return axios.post(`/domains/${id}/stop-words`, { words })
}

interface AddAliasLog {
  id: number
  wallet: string
  aliases: string[]
}

const addAliasLog = ({ id, ...data }: AddAliasLog) => {
  return axios.post(`/domains/${id}/aliases/add`, data)
}

interface DeleteAliasLog {
  id: number
  wallet: string
  aliases: string[]
}

const deleteAliasLog = ({ id, ...data }: DeleteAliasLog) => {
  return axios.post(`/domains/${id}/aliases/delete`, data)
}

interface ChangePrimaryAliasLog {
  id: number
  wallet: string
}

const changePrimaryAliasLog = ({ id, ...data }: ChangePrimaryAliasLog) => {
  return axios.post(`/domains/${id}/aliases/primary`, data)
}

interface GetLogsParams {
  page: number
  offset: number
  teamWallet?: string
  actionWallet?: string
  actionTypes?: string | []
  id?: number
}

const getDomainLogs = ({ id, ...params }: GetLogsParams) => {
  return axios.get(`/domains/${id}/logs`, { params })
}

const validateAddAdmin = (address: string) => {
  return axios.get(`/domains/team/validate/${address}`)
}

const validateAddModerator = (id: number, address: string) => {
  return axios.get(`/domains/${id}/moderators/validation/${address}`)
}

const retryAddDomain = (id: number) => {
  return axios.post(`/domains/retry/${id}`)
}

const apiService = {
  sendMetamaskAddress,
  login,
  getUser,
  getLogs,
  refreshAuthToken,
  isBEHealth,
  getLastMaintenanceState,
  switchMaintenanceMode,
  getDomains,
  getMyDomainsList,
  getDomainLogs,
  addDomain,
  domainAction,
  getAdmins,
  addAdmin,
  editAdmin,
  getAliases,
  addAlias,
  getDomain,
  reserveAlias,
  deleteReservedAlias,
  getModerators,
  addModerator,
  editModerator,
  getStopWords,
  saveStopWords,
  editDomain,
  existAlias,
  addAliasLog,
  deleteAliasLog,
  changePrimaryAliasLog,
  validateAddAdmin,
  validateAddModerator,
  retryAddDomain
}

export default apiService
