import React from 'react'
import styled from '@emotion/styled'

import SearchBar from 'src/components/SearchBar'
import { ButtonTransparent, Select } from 'src/components/common'
import { MEDIA_WIDTHS } from 'src/constants'

const rolesOptions = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Super Admin',
    value: 'superAdmin'
  }
]

interface Props {
  setFilters: React.Dispatch<React.SetStateAction<{ search: string; roles: string[] }>>
  filters: Record<string, any>
}

export const Filters = ({ filters, setFilters }: Props) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value
    setFilters((state) => ({ ...state, search }))
  }

  const handleChangeRole = (event: any) => {
    const selectedValue: any = event.target.value
    const isAll = selectedValue.includes('all')
    setFilters((state) => ({ ...state, roles: isAll ? [] : selectedValue }))
  }

  const onClearFilters = () => {
    setFilters({
      search: '',
      roles: []
    })
  }

  return (
    <Container>
      <SearchBar
        name="search"
        value={filters.search}
        onChange={handleSearchChange}
        placeholder="Search by Wallet or Name"
      />
      <Select value={filters.roles} onChange={handleChangeRole} multiple options={rolesOptions} />
      <ClearFiltersButton onClick={onClearFilters}>Clear</ClearFiltersButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 20px;
  > div:first-of-type {
    flex: 2;
  }

  > div:last-of-type {
    flex: 1;
  }
  button {
    flex: 1;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    flex-direction: column;
    > * {
      flex: 1;
    }
  }
`

const ClearFiltersButton = styled(ButtonTransparent)`
  background-color: #1e2223 !important;
  color: white;
  text-transform: none;
  white-space: nowrap;
  padding: 15px 22px !important;
  max-width: 136px;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    max-width: 100%;
  }
`
