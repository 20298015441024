import React, { useEffect } from 'react'
import moment from 'moment'
import { useStore } from 'effector-react'

import useMetaMask from 'src/hooks/useMetaMask'
import LoadingIndicator from 'src/components/loading-indicator'
import {
  MetamaskAuth,
  InstallMetamask,
  PendingSignature,
  RequestToSign,
  NotAllowed
} from 'src/components/MetamaskComponents'
import { $auth, $triggerSignature, setAuth, $user } from 'src/state/store'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/constants'

const ProtectedRoute = ({ children }: any) => {
  const { account, isLoading, signer } = useMetaMask()

  const { ethereum } = window as any
  const isMetamaskInstalled = typeof ethereum !== 'undefined'
  const auth = useStore($auth)
  const user = useStore($user)
  const triggerSignature = useStore($triggerSignature)

  const { isNotAllowed } = useHaveAccess({
    roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Owner, UserRoles.Moderator]
  })

  useEffect(() => {
    if (auth?.[account]?.logoutTime && moment(auth?.[account]?.logoutTime).isBefore(moment())) {
      delete auth?.[account]
      setAuth(auth)
    }
  }, [account, auth, triggerSignature])

  if (!isMetamaskInstalled) {
    return <InstallMetamask />
  }

  if (!account) {
    return <MetamaskAuth />
  }

  if (triggerSignature) {
    return <PendingSignature />
  }

  if (!auth?.[account]) {
    return <RequestToSign />
  }

  if ((user && isNotAllowed) || user?.deactivated) {
    return <NotAllowed />
  }

  // if (!user && location.pathname.includes('admin')) {
  //   return <Navigate to="/" />
  // }

  if (isLoading || !signer) {
    return <LoadingIndicator />
  }

  return children
}

export default ProtectedRoute
