import { createEffect, createEvent, createStore } from 'effector'

import { Domain, IUser } from 'src/types'

const localStorageAuth = window.localStorage.getItem('auth')

export const setAuth = createEffect((auth: any) => auth)

export const $auth = createStore(localStorageAuth ? JSON.parse(localStorageAuth) : {}).on(
  setAuth,
  (state, auth) => {
    window.localStorage.setItem('auth', JSON.stringify(auth))
    return auth
  }
)

export const saveConnectingHash = createEffect((hash: string) => hash)

export const $dataHash = createStore<string>('').on(saveConnectingHash, (state, hash) => {
  return hash
})

export const saveUserInStore = createEvent<IUser | null>()
export const saveIsUserLoading = createEvent<boolean>()

export const $user = createStore<IUser | null>(null).on(saveUserInStore, (_, user) => user)

export const $isUserDeactivated = createStore<boolean>(false).on(saveUserInStore, (_, user) =>
  Boolean(user?.deactivated)
)

export const $isLoadingUser = createStore<boolean>(Boolean(localStorage.getItem('auth'))).on(
  saveIsUserLoading,
  (_, isLoading) => isLoading
)

export const setIsMaintenanceModeActive = createEvent<boolean>()
export const $isMaintenanceModeActive = createStore<boolean>(false).on(
  setIsMaintenanceModeActive,
  (_, value) => value
)

export const setIsNotAllowedServer = createEvent<boolean>()
export const $isNotAllowedServer = createStore<boolean>(false).on(
  setIsNotAllowedServer,
  (_, value) => value
)

export const setDomainsList = createEvent<{ id: number; name: string }[]>()
export const $domainsList = createStore<{ id: number; name: string }[]>([]).on(
  setDomainsList,
  (_, domains) => domains
)

export const setDomain = createEvent<Domain | null>()
export const $domain = createStore<Domain | null>(null).on(setDomain, (state, domain) =>
  state && domain ? { ...state, ...domain } : domain
)

export const setIsShowNavigation = createEvent<boolean>()

export const triggerSignatureEffect = createEffect((trigger: boolean) => trigger)

export const $triggerSignature = createStore(false).on(triggerSignatureEffect, (state, trigger) => {
  // console.error('stored idx ', idx)
  return trigger
})

export const $isShowNavigation = createStore<boolean>(false).on(
  setIsShowNavigation,
  (_, payload) => payload
)
