import { ParamType } from 'ethers'
import ethereumLogoUrl from '../assets/ethereum.svg'
import { ENetworkTypes } from '../types'

export const SUPPORTED_CHAIN = (
  process.env.REACT_APP_SUPPORTED_CHAIN ? +process.env.REACT_APP_SUPPORTED_CHAIN : 11155111
) as number

export const CHAIN_INFO = {
  1: {
    blockExplorerUrls: ['https://etherscan.io/'],
    chainName: 'Ethereum',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    iconUrls: ethereumLogoUrl,
    networkType: ENetworkTypes.ETH
  },
  11155111: {
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    chainName: 'Sepolia',
    nativeCurrency: { name: 'Sepolia ETH', symbol: 'ETH', decimals: 18 },
    iconUrls: ethereumLogoUrl,
    networkType: ENetworkTypes.SEPOLIA
  }
} as {
  [key: number]: {
    blockExplorerUrls: string[]
    chainName: string
    nativeCurrency: { name: string; symbol: string; decimals: number }
    iconUrls: string
    networkType: ENetworkTypes
  }
}

export const MEDIA_WIDTHS = {
  upToExtremelySmall: 370,
  upToExtraSmall: 599,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280
}

export enum UserRoles {
  User = 'user',
  Owner = 'owner',
  Moderator = 'moderator',
  Admin = 'admin',
  SuperAdmin = 'superAdmin'
}

export const contractAddresses = {
  1: {
    RootNodeController: '0x379eD0A1A828DEA33Fa796E80C8517503c351747',
    BNSRegistry: '0x5db0E9eD56EF5B3760cE7887BAf215a39dE8d61E',
    BNSResolver: '0x9339Fd82dc2a53C7a404Cdb80a7f2aaABd535Cc7',
    ReverseRegistrar: '0x7d995d68F58b8658f45fFEA59c17532e3dB32a64'
  },
  11155111: {
    RootNodeController: '0xE5f5AC04Be149aD8382c19368533bF914dc5F3a5',
    BNSRegistry: '0x2C1E6E5F4cd8Aa0f103e8653ea109A0b8b1aAf70',
    BNSResolver: '0x86d28b9Cf5525b77EEED3B15958B376C2081CaD8',
    ReverseRegistrar: '0x45ebf4b89dd58D471AD0F9e7cB82f646D8564161'
  }
} as Record<number, Record<string, string>>

export const ALIAS_ENCODE = ParamType.from({
  components: [
    {
      internalType: 'string',
      name: 'label',
      type: 'string'
    },
    {
      internalType: 'uint256',
      name: 'duration',
      type: 'uint256'
    },
    {
      internalType: 'bool',
      name: 'isPrimary',
      type: 'bool'
    }
  ],
  internalType: 'struct SignatureHandler.Label[]',
  name: 'labels',
  type: 'tuple[]'
})

export enum BcRoles {
  DEFAULT_ADMIN_ROLE = '0x0000000000000000000000000000000000000000000000000000000000000000',
  ADMIN_ROLE = '0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775',
  MODERATOR_ROLE = '0x71f3d55856e4058ed06ee057d79ada615f65cdf5f9ee88181b914225088f834f'
}
