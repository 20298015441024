import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useForm, FormProvider, FieldValues } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { IAdmin } from 'src/types'
import CustomModal from 'src/components/modal/custom-modal'
import {
  ButtonPrimary,
  FormField,
  ButtonPrimaryOutlined,
  ButtonDangerOutlined,
  ButtonSuccessOutlined
} from 'src/components/common'
import { MEDIA_WIDTHS } from 'src/constants'

import { validationSchema } from './validation-schema'
import apiService from 'src/services/api'
import LoadingIndicator from 'src/components/loading-indicator'
import { useControllerContract } from 'src/hooks/useContracts'
import { errorToast } from 'src/utils'

interface Props {
  handleClose: (needRefresh?: boolean) => void
  initialValues: IAdmin | null
}

export const AdminModal = ({ handleClose, initialValues }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const controller = useControllerContract()

  const formMethods = useForm({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues: {
      name: initialValues?.name || '',
      ethAddress: initialValues?.user?.ethAddress || '',
      deactivated: Boolean(initialValues?.deactivated)
    },
    resolver: yupResolver(validationSchema)
  })

  const values: FieldValues = formMethods.watch()

  const submitForm = async (values: FieldValues) => {
    try {
      setIsLoading(true)
      if (initialValues) {
        await apiService.editAdmin({
          id: initialValues.id,
          name: values.name,
          deactivated: values.deactivated
        })
      } else {
        const validation = await apiService.validateAddAdmin(values.ethAddress)

        if (!validation.data.isValid) {
          throw new Error('Wallet already in team')
        }
        const tx = await controller.setAdmin(values.ethAddress)
        await tx.wait()
        await apiService.addAdmin({
          name: values.name,
          walletAddress: values.ethAddress,
          deactivated: values.deactivated,
          role: 'admin',
          domains: []
        })
      }
      toast.success(initialValues ? 'Changes saved successfully' : 'Admin added successfully')
      handleClose(true)
    } catch (error: any) {
      errorToast(error, controller)
    } finally {
      setIsLoading(false)
    }
  }

  const changeDeactivate = async () => {
    try {
      setIsLoading(true)
      if (initialValues) {
        if (values.deactivated) {
          const tx = await controller.setAdmin(values.ethAddress)
          await tx.wait()
        } else {
          const tx = await controller.removeAdmin(values.ethAddress)
          await tx.wait()
        }
        await apiService.editAdmin({
          id: initialValues.id,
          name: values.name,
          deactivated: !values.deactivated
        })
        toast.success(`Admin ${!values.deactivated ? 'deactivated' : 'reactivated'} successfully`)
        handleClose(true)
      }
    } catch (error) {
      console.log('log => error', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CustomModal
      isOpen
      handleClose={handleClose}
      title={initialValues ? 'Edit Admin' : 'Add Admin'}
    >
      {isLoading && <LoadingIndicator />}
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(submitForm)}>
          <FormField name="name" label="Name" placeholder="Name Surname" value={values.name} />
          <FormField
            disabled={Boolean(initialValues)}
            name="ethAddress"
            label="Wallet address"
            placeholder="Specify the wallet"
            value={values.ethAddress}
          />
          <div className="actions">
            {initialValues ? (
              <>
                <ButtonPrimary fullWidth type="submit">
                  Save Changes
                </ButtonPrimary>
                {values.deactivated ? (
                  <ButtonSuccessOutlined fullWidth onClick={changeDeactivate}>
                    Re-Activate
                  </ButtonSuccessOutlined>
                ) : (
                  <ButtonDangerOutlined fullWidth onClick={changeDeactivate}>
                    Deactivate
                  </ButtonDangerOutlined>
                )}
              </>
            ) : (
              <>
                <ButtonPrimaryOutlined fullWidth onClick={() => handleClose()}>
                  Cancel
                </ButtonPrimaryOutlined>
                <ButtonPrimary fullWidth type="submit">
                  Add Admin
                </ButtonPrimary>
              </>
            )}
          </div>
        </Form>
      </FormProvider>
    </CustomModal>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .actions {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
      flex-direction: column;
    }
  }
`
