import React from 'react'
import styled from '@emotion/styled'
import { TableContainer, Tooltip, capitalize } from '@mui/material'
import moment from 'moment'
import { Box, css } from '@mui/system'

import { ILog } from 'src/types'

import { Table, HeaderRow, BodyRow, CellPrimary } from 'src/components/table'
import { AddressesCell } from 'src/components/table/addresses-cell'

interface Copied {
  [key: string]: boolean
}

const headerData: string[] = ['Date', 'Team member', 'Role', 'Action performed']

export const LogsTable = ({ data }: { data: ILog[] }) => {
  const renderActionRow = (action: string, idx: string) => {
    // Regex for finding ethAddress
    const regex = /(\b0x[a-fA-F0-9]{40}\b)/g
    const actionWithoutNull = action.replace(' null ', ' ')
    const ethAddresses = actionWithoutNull.match(regex)
    const textParts = actionWithoutNull.split(regex).filter((item) => !item.match(regex))

    return textParts.map((text, id) => {
      const copyLink = ethAddresses?.at(id) ? (
        <StyledCopyBox
          mx="2px"
          display="inline-block"
          justifyContent="flex-start"
          alignItems="center"
        >
          <AddressesCell address={ethAddresses.at(id) as string} id={idx + id.toString()} />
        </StyledCopyBox>
      ) : (
        <></>
      )

      return (
        <>
          {text} {copyLink}
        </>
      )
    })
  }

  return (
    <TableContainer>
      <Table aria-label="customized table" sx={{ minWidth: '800px' }}>
        <HeaderRow rowTemplate={rowTemplate}>
          {headerData.map((header) => (
            <CellPrimary key={header}>{header}</CellPrimary>
          ))}
        </HeaderRow>
        <>
          {Boolean(data)
            ? data.map((row: ILog, idx) => (
                <BodyRow key={row.id} rowTemplate={rowTemplate}>
                  <CellPrimary>
                    <Tooltip
                      title={
                        <>
                          {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                          <br />
                          {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                        </>
                      }
                      placement="top"
                      arrow
                    >
                      <div>{moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')} (GMT)</div>
                    </Tooltip>
                  </CellPrimary>
                  <CellPrimary>
                    <AddressesCell
                      address={row.teamMember.user?.ethAddress || ''}
                      name={row.teamMember.name}
                      id={`${idx}-1`}
                    />
                  </CellPrimary>
                  <CellPrimary>{capitalize(row?.teamMember?.role || '')}</CellPrimary>
                  <CellPrimary>{renderActionRow(row.action, `${idx}-2`)}</CellPrimary>
                </BodyRow>
              ))
            : null}
        </>
      </Table>
    </TableContainer>
  )
}

const rowTemplate = css`
  grid-template-columns: 235px 160px 125px minmax(250px, 1fr);
`

const StyledCopyBox = styled(Box)`
  .text-to-copy {
    margin-right: 4px !important;
  }
`
