import { useEffect, useRef } from 'react'

export const usePrev = (value: any) => {
  const prev = useRef()

  useEffect(() => {
    prev.current = value
  }, [value])

  return prev.current
}
