import * as React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { Box, InputAdornment } from '@mui/material'

import { isMobile } from 'src/utils/isMobile'
import { colors } from 'src/constants/colors'
import { TextFieldPrimary } from 'src/components/common'

interface Props {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  value: string
  placeholder?: string
  name?: string
  withMargin?: boolean
  size?: 'small' | 'middle'
}

const SearchBar = ({ onChange, value, placeholder = 'Search', name, withMargin, size }: Props) => {
  const mobile = isMobile()

  const margin = mobile ? '15px' : '24px'
  const height = size === 'small' ? '40px' : '50px'

  return (
    <Box mt={withMargin ? margin : 0} mb={withMargin ? margin : 0}>
      <TextFieldPrimary
        fullWidth
        sx={{
          '& > *': { height },
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 0
          },
          '& input': { fontSize: '16px' }
        }}
        value={value}
        name={name}
        onChange={onChange}
        id="input-with-icon-textfield"
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ padding: 0 }} />
            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}

export default SearchBar
