import React, { useLayoutEffect, Suspense } from 'react'

import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { Outlet, useLocation } from 'react-router-dom'

import Header from '../header'
import Footer from '../footer'
import LoadingIndicator from '../loading-indicator'

const AppLayout = () => {
  const location = useLocation()

  //Effects
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <>
      <Suspense fallback={<LoadingIndicator />}>
        <BoxWrapper className="test">
          <Header />
          <Outlet />
          <Footer />
        </BoxWrapper>
      </Suspense>
    </>
  )
}

const BoxWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export default AppLayout
