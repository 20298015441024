import apiService from '../services/api'

interface Props {
  hash?: string
  account?: string | null
}

export const login = async ({ hash, account }: Props) => {
  if (hash && account) {
    try {
      const result = await apiService.login(account, hash)
      return result
    } catch (e) {
      console.error({ ERROR19: e })
    }
  }
  return null
}
