import React from 'react'
import { Box, Grid, Link, styled, Typography } from '@mui/material'
import { ContainerWrapper } from './main/index'
import { isMobile } from '../utils/isMobile'

export const PrivacyAndPolicyPage = () => {
  const mobile = isMobile()

  return (
    <ContainerWrapper>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant={mobile ? 'h2' : 'subtitle1'}
              sx={{
                margin: '10px 0',
                fontWeight: 700
              }}
            >
              Privacy & Cookie Policy
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 300 }}>
              We respect your privacy and do NOT use cookies. It may sound strange, we know. In a
              time when most companies profit from selling their customers&apos; personal data, we
              chose the opposite path. It&apos;s not the first time that we haven&apos;t followed
              the most traveled road, it will not be the last. We make money building real products
              and providing real services, so we don&apos;t need (and we don&apos;t want) to give
              away your personal data. Moreover, because we don&apos;t use cookies, we don&apos;t
              need to slow down the website with one of the privacy plugins. A faster website saves
              your time too. We all win!
            </Typography>
            <Typography
              variant={mobile ? 'h2' : 'subtitle1'}
              sx={{ margin: '10px 0', fontWeight: 700 }}
            >
              What Kind of Data Do We Save
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 300 }}>
              If you request our newsletter, your email is recorded (outside this website) with our
              email provider{' '}
              <LinkOverrides
                // classList="privacy-link"
                href="https://convertkit.com?lmref=qNpXeg"
                target="_blank"
              >
                Convertkit.
              </LinkOverrides>{' '}
              If you fill out our contact form, we&apos;ll collect your information to contact you
              back. That&apos;s it! We don&apos;t share your information with third parties, or
              spam. Never!
            </Typography>{' '}
            <Typography
              variant={mobile ? 'h2' : 'subtitle1'}
              sx={{
                margin: '10px 0',
                fontWeight: 700
              }}
            >
              Your browser might treat you differently than us
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 300 }}>
              Your browser, app, or smartphone might treat you differently than us. Always be
              careful! We suggest consulting the Help section of your browser or taking a look at
              the website{' '}
              <LinkOverrides
                // classList="privacy-link"
                href="https://www.aboutcookies.org"
                target="_blank"
              >
                About Cookies,
              </LinkOverrides>
              which offers guidance for all modern browsers.
            </Typography>
            <Typography
              variant={mobile ? 'h2' : 'subtitle1'}
              sx={{ margin: '10px 0', fontWeight: 700 }}
            >
              GDPR
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 300 }}>
              If you are a resident of the European Economic Area (EEA), you have certain data
              protection rights, and the websites you view have certain duties. We are committed to
              protecting your data and complying with the regulation. This includes your right to{' '}
              <b>access and rectification</b> (you may request a copy of the information we process
              about you and ask us to rectify any incorrect data), <b>erasure or restriction</b> (in
              some circumstances, you may ask us to delete or restrict our processing of your data,
              but we cannot delete any data we are legally required to process),
              <b>object to processing</b> (in some circumstances, you may ask us to stop processing
              your data), and <b>data portability</b> (in some circumstances, you may ask us to
              transfer your data to you or another organization). Moreover, our website is GDPR
              compliant with{' '}
              <LinkOverrides
                // classList="privacy-link"
                href="https://usefathom.com/ref/LD1TWE"
                target="_blank"
              >
                {' '}
                Fathom
              </LinkOverrides>
              . Fathom is the privacy-first alternative to Google Analytics. Like us, they don’t use
              cookies and respect your privacy. Their company is based in the beautiful city of
              Victoria, Canada (Yes, they are nice and Canadian. Don’t even try that joke …) If you
              want to know more about how the data are managed, check{' '}
              <LinkOverrides
                // classList="privacy-link"
                href="https://usefathom.com/ref/LD1TWE"
                target="_blank"
              >
                {' '}
                Fathom’s privacy page{' '}
              </LinkOverrides>
              . Please get in touch with us if you have any questions about how we handle your data
              or want to exercise one of your rights. You can email <b>privacy@xype.com</b>, and you
              are entitled to a reply within 30 days.
            </Typography>
            <Typography
              variant={mobile ? 'h2' : 'subtitle1'}
              sx={{
                margin: '10px 0',
                fontWeight: 700
              }}
            >
              Data Controller
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 300 }}>
              For the purpose of EU law, the following entity is the Data Controller: Block Prime
              Ltd, privacy@xype.com.
            </Typography>
            <Typography
              variant={mobile ? 'h2' : 'subtitle1'}
              sx={{ margin: '10px 0', fontWeight: 700 }}
            >
              UK Information Commissioner&apos;s Office (ICO)
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 300 }}>
              We are committed to complying with the privacy guidance issued by the ICO, the UK
              Information Commissioner&apos;s Office. More info:{' '}
              <LinkOverrides
                // classList="privacy-link"
                href="https://ico.org.uk/"
                target="_blank"
              >
                {' '}
                ico.org.uk{' '}
              </LinkOverrides>
            </Typography>
            <Typography
              variant={mobile ? 'h2' : 'subtitle1'}
              sx={{
                margin: '10px 0',
                fontWeight: 700
              }}
            >
              Changes to This Policy
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 300 }}>
              We review our privacy practices from time to time and reserve the right to make
              changes to this Privacy Policy at any time. If we do make material changes, we will
              give notice via this Website. We recommend that you check the contents of the Privacy
              Policy whenever you have questions about privacy and our use of data, referring to the
              date of the last amendment listed at the bottom of the Privacy Policy.
            </Typography>
            <Typography
              variant={mobile ? 'h2' : 'subtitle1'}
              sx={{ margin: '10px 0', fontWeight: 700 }}
            >
              Questions About This Policy
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 300 }}>
              If you have any questions or comments about this Privacy Policy, please email us at
              privacy@xype.com Date of Last Amendment: 17 August 2022
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ContainerWrapper>
  )
}

const LinkOverrides = styled(Link)`
  background: transparent;
  color: #000;
  border: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
`
