import { css, TableContainer, Box, Tooltip } from '@mui/material'
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import styled from '@emotion/styled'

import deleteIcon from 'src/assets/delete.svg'
import editIcon from 'src/assets/edit.svg'
import primaryIcon from 'src/assets/primary.svg'
import { Alias } from 'src/types'
import { colors } from 'src/constants/colors'
import { Table, HeaderRow, BodyRow, CellPrimary } from 'src/components/table'
import { Dropdown } from 'src/components/common'

import { AddressesCell } from 'src/components/table/addresses-cell'
import { useDomainData } from 'src/hooks/use-domain-data'
import { ZeroAddress } from 'ethers'

const statusColors = {
  active: colors.$success,
  expired: colors.$error,
  inactive: colors.$primaryLight,
  reserved: colors.$lightYellow
} as Record<string, string>

const headerData: string[] = ['Alias', 'Wallet', 'Status', 'Created at', '']

interface Props {
  setEditData: (value: Alias | null) => void
  items: Alias[]
  onDelete: (value: Alias) => void
  disableActions: boolean
}

export const AliasesTable = ({ items, setEditData, onDelete, disableActions }: Props) => {
  const { name } = useDomainData()

  const onEdit = (user: Alias) => {
    setEditData(user)
  }

  const getDateText = (item: Alias) => {
    if (item.status === 'active') {
      return `(Expires at ${moment(item.expiryDate).format('DD MMM YYYY')})`
    }
    if (item.status === 'inactive') {
      if (moment(item.expiryDate).add(3, 'months').isBefore(moment())) {
        return null
      }
      return `(Grace end at ${moment(item.expiryDate).add(3, 'months').format('DD MMM YYYY')})`
    }
    return null
  }

  return (
    <>
      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: '750px' }}>
          <HeaderRow rowTemplate={rowTemplate}>
            {headerData.map((header) => (
              <CellPrimary key={header}>{header}</CellPrimary>
            ))}
          </HeaderRow>
          <>
            {items.map((row, idx) => {
              const status = row.ownerAddress === ZeroAddress ? 'expired' : row.status
              return (
                <BodyRow key={row.id} rowTemplate={rowTemplate}>
                  <CellPrimary style={{ display: 'flex', alignItems: 'center' }}>
                    {row.name}
                    {row.status === 'reserved' && `.${name}`}
                    {row.isPrimary && (
                      <Tooltip title="Primary alias" placement="top" arrow>
                        <img src={primaryIcon} style={{ marginLeft: '8px' }} alt="primary" />
                      </Tooltip>
                    )}
                  </CellPrimary>
                  <CellPrimary>
                    {row.ownerAddress ? (
                      <AddressesCell address={row.ownerAddress} id={`${idx}_owner`} />
                    ) : (
                      '-'
                    )}
                  </CellPrimary>
                  <CellPrimary>
                    <Box
                      style={{
                        color: statusColors[status],
                        textTransform: 'capitalize'
                      }}
                    >
                      {status}
                    </Box>
                    {row.expiryDate && <div className="date">{getDateText(row)}</div>}
                  </CellPrimary>
                  <CellPrimary>{moment(row.createdAt).format('DD MMM YYYY')}</CellPrimary>

                  <CellPrimary>
                    {status !== 'expired' && (
                      <Dropdown
                        disabled={disableActions}
                        menuContent={
                          <ItemsContainer>
                            <Item onClick={() => onEdit(row)}>
                              <img src={editIcon} alt="editIcon" />
                              Edit
                            </Item>
                            <Item
                              onClick={() => {
                                onDelete(row)
                              }}
                            >
                              <img src={deleteIcon} alt="deleteIcon" />
                              Delete
                            </Item>
                          </ItemsContainer>
                        }
                      >
                        <MoreVertIcon fontSize="small" className="moreIcon" />
                      </Dropdown>
                    )}
                  </CellPrimary>
                </BodyRow>
              )
            })}
          </>
        </Table>
      </TableContainer>
    </>
  )
}

const rowTemplate = css`
  grid-template-columns: 1fr 1fr minmax(210px, 1fr) 150px 50px;
  .moreIcon {
    fill: ${colors.$blue};
    cursor: pointer;
  }
  .date {
    color: ${colors.$primaryLight};
    font-size: 14px !important;
    font-weight: 400;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Item = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.$primary2};
  width: 140px;
  &:hover {
    border-radius: 3px;
  }
`
