import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

import { ButtonPrimary, DomainName, ErrorMessage, TextFieldPrimary } from 'src/components/common'

import { Filters } from './filters'
import { colors } from 'src/constants/colors'
import apiService from 'src/services/api'
import { useDomainData } from 'src/hooks/use-domain-data'
import LoadingIndicator from 'src/components/loading-indicator'
import { toast } from 'react-toastify'

export const StopWordsPage = () => {
  const [words, setWords] = useState<string[]>([])
  const [initialWords, setInitialWords] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState<{ search: string }>({
    search: ''
  })
  const [currWord, setCurrWord] = useState('')

  const timeout = useRef(0 as any)

  const { id } = useDomainData()

  const fetchStopWords = async () => {
    try {
      setIsLoading(true)
      const res = await apiService.getStopWords(id, filters.search)
      if (res.data?.words) {
        setWords(res.data.words.split(','))
        setInitialWords(res.data.words.split(','))
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setWords([])
    setInitialWords([])
    setFilters({ search: '' })
    setCurrWord('')
  }, [id])

  useEffect(() => {
    if (!id) return
    clearTimeout(timeout.current)
    timeout.current = setTimeout(fetchStopWords, 250)
  }, [id])

  const onChangeWords = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value || ''
    if (value && value.replaceAll(' ', '').length === 0) return

    if (value.includes(' ')) {
      const newWords = value
        .replaceAll(',', '')
        .split(' ')
        .filter(
          (word, index, array) =>
            Boolean(word) && index === array.indexOf(word) && !words.includes(word)
        )
      setWords((state) => [...state, ...newWords])
      setCurrWord('')
    } else {
      setCurrWord(value)
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' && currWord) {
      const newWords = currWord
        .replaceAll(',', '')
        .split(' ')
        .filter((word) => Boolean(word) && !words.includes(word))
      setWords((state) => [...state, ...newWords])
      setCurrWord('')
    }
  }

  const onBlur = () => {
    if (currWord) {
      const newWords = currWord
        .replaceAll(',', '')
        .split(' ')
        .filter((word) => Boolean(word) && !words.includes(word))
      setWords((state) => [...state, ...newWords])
      setCurrWord('')
    }
  }

  const deleteWord = (index: number) => {
    setWords((state) => {
      const tempWords = [...state]
      tempWords.splice(index, 1)
      return tempWords
    })
  }

  const onWordsClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.target as HTMLDivElement
    if (id === 'words') {
      document.getElementById('word-input')?.focus()
    }
  }

  const withoutChanges = useMemo(
    () => isEqual(sortBy(initialWords), sortBy(words)),
    [words, initialWords]
  )

  const onSaveWords = async () => {
    try {
      setIsLoading(true)
      await apiService.saveStopWords(id, words.join(','))
      toast.success('Words saved successfully')
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const filteredWords = useMemo(
    () => words.filter((word) => word.toLowerCase().includes(filters.search.toLowerCase())),
    [words, filters.search]
  )

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      <DomainName />
      <Header>
        <SectionLabel>Stop Words</SectionLabel>
      </Header>
      <Filters filters={filters} setFilters={setFilters} />
      <Words onClick={onWordsClick} id="words">
        {filteredWords.map((word, index) => (
          <Word key={`${word}-${index}`}>
            <div className="label">{word}</div>
            <div onClick={() => deleteWord(index)} className="delete">
              <CloseOutlinedIcon sx={{ width: '20px', height: '20px' }} />
            </div>
          </Word>
        ))}
        <TextFieldPrimary
          id="word-input"
          value={currWord}
          onChange={onChangeWords}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          sx={{ flex: 1 }}
        />
      </Words>
      <ButtonPrimary disabled={withoutChanges} onClick={onSaveWords} sx={{ width: '300px' }}>
        Save Changes
      </ButtonPrimary>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .actions {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
  }
`
const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`

const Words = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  background-color: rgba(212, 219, 231, 0.16);
  padding: 28px;
  .MuiInputBase-root {
    background-color: transparent !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  input {
    font-size: 16px !important;
    padding: 4px 8px !important;
    height: 20px !important;
    min-height: 20px !important;
    width: -webkit-fill-available;
    /* width: 150px !important; */
  }
`

const Word = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 4px;
  padding: 4px 8px;
  background-color: ${colors.$border};
  border-radius: 4px;
  min-width: 0px;
  .label {
    color: ${colors.$secondary};
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .delete {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`
