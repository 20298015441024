import { useEffect } from 'react'
import styled from '@emotion/styled'
import { Container } from '@mui/material'

import { colors } from 'src/constants/colors'

import useMetaMask from '../../hooks/useMetaMask'

export const MainPage = () => {
  const { disconnect } = useMetaMask()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const logout = urlParams.get('logout')
    if (logout) {
      localStorage.clear()
      disconnect()
      window.location.href = window.location.origin
    }
  }, [disconnect])

  return null
}

export const ContainerWrapper = styled(Container)`
  max-width: 960px !important;
  padding: 60px;
  margin-top: 22px;
  margin-bottom: 22px;
  box-shadow: ${colors.$boxShadow};
  width: 100%;
  border-radius: 16px;

  @media (max-width: 599px) {
    & .title-block {
      font-size: 20px;
    }
  }
  @media (max-width: 768px) {
    padding: 16px;
  }
`
