import React, { Component } from 'react'
import styled from '@emotion/styled'
import { colors } from 'src/constants/colors'
import { Box } from '@mui/system'

const FallbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

const BodyWrapper = styled.div<{ margin?: string }>`
  padding: 1rem;
  width: 90%;
`

const CodeBlockWrapper = styled.div`
  overflow: auto;
  white-space: pre;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  padding: 18px 24px;
  color: ${colors.$default};
`

const SomethingWentWrongWrapper = styled.div`
  padding: 6px 24px;
`

type ErrorBoundaryState = {
  error: Error | null
  isChunkError: boolean
}

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed'

export default class ErrorBoundary extends Component<unknown, ErrorBoundaryState> {
  constructor(props: unknown) {
    super(props)
    this.state = { error: null, isChunkError: false }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState | undefined {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(PageHasBeenForceRefreshed) || 'false'
    ) as boolean
    if (!pageHasAlreadyBeenForceRefreshed) {
      window.localStorage.setItem(PageHasBeenForceRefreshed, 'true')
      window.location.reload()
      return { error: null, isChunkError: false }
    }
    return { error, isChunkError: /Loading chunk [\d]+ failed/.test(error.message) }
  }

  render() {
    const { error, isChunkError } = this.state

    if (error !== null && isChunkError) {
      return (
        <FallbackWrapper>
          <BodyWrapper>
            <div style={{ justifyContent: 'center', height: '100%' }}>
              <SomethingWentWrongWrapper>
                <Box fontSize={24} fontWeight={600}>
                  Error occured while loading the content
                </Box>

                <Box fontSize={20} fontWeight={600}>
                  Please, refresh the page
                </Box>
              </SomethingWentWrongWrapper>
            </div>
          </BodyWrapper>
        </FallbackWrapper>
      )
    } else if (error !== null) {
      // find out what is this const encodedBody = encodeURIComponent(issueBody(error))
      return (
        <FallbackWrapper>
          <BodyWrapper>
            <SomethingWentWrongWrapper>
              <Box fontSize={24} fontWeight={600}>
                Something went wrong
              </Box>
            </SomethingWentWrongWrapper>
            <CodeBlockWrapper>
              <code>
                <Box fontSize={10}>{error.stack}</Box>
              </code>
            </CodeBlockWrapper>
          </BodyWrapper>
        </FallbackWrapper>
      )
    }
    return (this.props as any).children
  }
}
