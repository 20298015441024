import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { useStore } from 'effector-react'

import AppLayout from 'src/components/app-layout'
import AdminLayout from 'src/components/admin-layout'
import AuthLayout from 'src/components/auth-layout'
import { $domainsList } from 'src/state/store'
import { UserRoles } from 'src/constants'
import { useHaveAccess } from 'src/hooks/useAccessController'

import ProtectedRoute from './protected-route'

import { MainPage } from './main'
import { PrivacyAndPolicyPage } from './privacy-and-policy'
import { NotFoundPage } from './not-found'
import { DomainsPage } from './domains'
import { AdminsPage } from './admins'
import { LogsPage } from './logs'
import {
  AliasesPage,
  ModeratorsPage,
  StopWordsPage,
  IntegrationPage,
  DomainWrapper
} from './domain'
import { DomainForm } from './domains/domain-form'

const App = () => {
  const { isAllowed: isAdmin, isNotAllowed: isNotAdmin } = useHaveAccess({
    roles: [UserRoles.SuperAdmin, UserRoles.Admin]
  })

  const domainsList = useStore($domainsList)

  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route element={<AppLayout />}>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  {domainsList[0] ? (
                    <Navigate
                      to={isNotAdmin ? `./domain/${domainsList[0].name}/aliases` : './domains'}
                    />
                  ) : (
                    isAdmin && <Navigate to={'./domains'} />
                  )}
                </ProtectedRoute>
              }
            ></Route>
            <Route path="*" element={<NotFoundPage />}></Route>
            <Route path="/privacy-policy" element={<PrivacyAndPolicyPage />}></Route>
          </Route>
          <Route element={<AdminLayout />}>
            {isAdmin && (
              <Route
                path="domains"
                element={
                  <ProtectedRoute>
                    <DomainsPage />
                  </ProtectedRoute>
                }
              />
            )}
            {isAdmin && (
              <Route
                path="domains/new"
                element={
                  <ProtectedRoute>
                    <DomainForm />
                  </ProtectedRoute>
                }
              />
            )}
            {isAdmin && (
              <Route
                path="domains/edit/:id"
                element={
                  <ProtectedRoute>
                    <DomainForm />
                  </ProtectedRoute>
                }
              />
            )}
            {isAdmin && (
              <Route
                path="admins"
                element={
                  <ProtectedRoute>
                    <AdminsPage />
                  </ProtectedRoute>
                }
              />
            )}
            {isAdmin && (
              <Route
                path="logs"
                element={
                  <ProtectedRoute>
                    <LogsPage />
                  </ProtectedRoute>
                }
              />
            )}
            <Route
              path="domain/:domain/aliases"
              element={
                <ProtectedRoute>
                  <DomainWrapper>
                    <AliasesPage />
                  </DomainWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path="domain/:domain/moderators"
              element={
                <ProtectedRoute>
                  <DomainWrapper>
                    <ModeratorsPage />
                  </DomainWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path="domain/:domain/stop-words"
              element={
                <ProtectedRoute>
                  <DomainWrapper>
                    <StopWordsPage />
                  </DomainWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path="domain/:domain/integration"
              element={
                <ProtectedRoute>
                  <DomainWrapper>
                    <IntegrationPage />
                  </DomainWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path="domain/:domain/logs"
              element={
                <ProtectedRoute>
                  <DomainWrapper>
                    <LogsPage />
                  </DomainWrapper>
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default App
