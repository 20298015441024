import { Contract } from 'ethers'

import { UserRoles } from 'src/constants'

export type TTokenType = 'ERC1155' | 'ERC721'
export type WhiteListStatuses = 'PENDING' | 'ACCEPTED' | 'REJECTED'

export type CurrencyInfoProps = {
  tokenBalance: string
  symbol: string
  contract: Contract
}
export type CurrencyListProps = {
  symbol: string
  icon: string
  balance: string
}
export type PriceInfoProps = {
  price: bigint
  priceConverted: string
  nftCount: number
  nftPrice: bigint
}
export type AvailabilityInfoProps = {
  nftName?: string
  maxAmount?: string
  availableAmount?: string | number
  balance?: string
  sales?: number
}

export enum EMode {
  PROD = 'PROD',
  TEST = 'TEST'
}

export type IModifyModeForQuery<T> = T & {
  mode: EMode
}

export interface IIsAllowBuyNFTs {
  isCanBuySecondNft: boolean
  isCanBuyFoundingNft: boolean
}

export interface IUserNftSales {
  secondNftSales: number
  foundingNftSales: number
}

export interface ISendEmailStatus {
  message: string
}

export interface ISetHiddenUnhiddenUser {
  userId: number
  hidden: boolean
}

export interface BuyNFT {
  nftType: string // | ENftTypes,
  amount: number
  chainId: number // | 1(Mainnet) | 11155111(Sepolia),
}

export interface ICryptoResource {
  balance: string
  threshold: number
}
export interface INFTResource {
  balance: number
  threshold: number
}

export interface IResources {
  usdc?: ICryptoResource
  eth?: ICryptoResource
  foundingOwners?: INFTResource
  secondOwners?: INFTResource
}

enum ENftTypes {
  Founding = 'Founding',
  Second = 'Second'
}

export enum ENetworkTypes {
  ETH = 'ETH',
  SEPOLIA = 'SEPOLIA'
}

export interface AdminSendNft {
  nftType?: ENftTypes
  amount: number
  ethAddress: string
  networkType: ENetworkTypes
}

export interface IAdmin {
  id: number
  userId: number
  domainId: number | null
  name: string
  role: UserRoles
  deactivated: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  user: {
    principal: string
    id: number
    name: string
    tenant: string
    language: string
    role: string
    ethAddress: string
    deactivated: boolean
  }
  domain: Domain | null
}

export interface IUser {
  createdAt: string
  principal: string
  id: number
  tenant: string
  language: string
  role: UserRoles
  ethAddress: string
  isBuddy: boolean
  deactivated: boolean
  memberFrom: string
  hidden: boolean
  sales: number
  name?: string
  photoId?: number
  buddyId?: number
  photo?: string
  domains: { domainId: number; role: UserRoles }[]
  funds: { fundId: number; role: UserRoles }[]
}

export interface Domain {
  id: number
  name: string
  url: string
  companyName: string
  treasuryAddress: string
  contractAddress: string
  installTxHash: string
  reverseTxHash: string
  resolveTxHash: string
  serviceFee: string | number
  txHash: string
  status: string
  paymentId: number
  ownerId: number
  networkId: number
  createdById: number
  expiresAt: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  owner: {
    id: number
    userId: number
    domainId: number
    name: string
    role: string
    deactivated: false
    createdAt: string
    updatedAt: string
    deletedAt: string | null
    user: IUser
  }
  payment: {
    id: number
    currency: string
    priceOfStandart: number
    priceOfPremiumA: null
    priceOfPremiumB: null
    limit: number
    unlimited: boolean
    type: string
    subscription: string | null
    createdAt: string
    updatedAt: string
    deletedAt: string | null
  }
}

export interface Fund {
  id: number
  name: string
  treasuryAddress: string
  airdropContract: string
  operatorId: number
  networkId: number
  countryId: number | null
  tokenId: number | null
  createdById: number | null
  createdAt: string | null
  updatedAt: string | null
  deletedAt: string | null
  investorCount?: number
  createdBy: IUser
}

export interface ResponseWithPagination<T> {
  items: T[]
  itemCount: number
  totalItems: number
  offset: number
  page: number
  totalPages: number
  nextPage?: number
  prevPage?: number
  totalInvestments?: number
}

export interface Investor {
  id: number
  isMotherTribe: boolean
  walletAddress: string
  name: string
  email: string | null
  whitelistStatus: WhiteListStatuses | null
  investment: number
  introducerId: number | null
  fundId: number
  hidden: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  introducer: (Omit<IUser, 'ethAddress'> & { walletAddress: string }) | null
}

export interface Investment {
  id: number
  investorId: number
  investment: number
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  investor: Investor
}

export interface TeamMember {
  id: number
  userId: number
  fundId: number
  name: string | null
  role: UserRoles
  deactivated: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  user: IUser
  fund: Fund
}

export interface ILog {
  id: number
  teamMemberId: number
  actionType: string
  action: string
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date
  teamMember: TeamMember
}

export interface PaginationTypes {
  page: number
  offset: number
}

export interface Alias {
  id: string
  name: string
  createdAt: string
  walletAddress: string
  status: string
  expiryDate: string
  isPrimary?: boolean
  primaryAlias: string
  aliases?: GqlAlias[]
  ownerAddress?: string
}

export interface GqlAlias {
  id: string
  name: string
  expiryDate?: string
  createdAt: string
  owner: {
    id: string
    primary: null | string
    domains: {
      id: string
      name: string
      expiryDate: string
      createdAt: string
      owner: {
        primary: null | string
      }
    }[]
  }
}

export interface Moderator {
  id: number
  userId: number
  domainId: number
  name: string
  role: string
  deactivated: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  user: IUser
}
