import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useForm, FormProvider, FieldValues } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Checkbox, css } from '@mui/material'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { Interface, MaxUint256 } from 'ethers'

import { Domain } from 'src/types'
import {
  ButtonPrimary,
  FormField,
  ButtonPrimaryOutlined,
  ButtonDangerOutlined,
  PageTitle,
  ButtonSuccessOutlined
} from 'src/components/common'
import { BcRoles, MEDIA_WIDTHS } from 'src/constants'
import { colors } from 'src/constants/colors'

import { networks, Paths } from 'src/constants/currency'
import useMetaMask from 'src/hooks/useMetaMask'
import { useIsOpen } from 'src/hooks/use-is-open'
import apiService from 'src/services/api'
import LoadingIndicator from 'src/components/loading-indicator'
import { useRegistrarContract } from 'src/hooks/useContracts'
import { Registrar__factory } from 'src/contract-types'
import { errorToast } from 'src/utils'

import { validationSchema } from './validation-schema'

const subscriptionText = {
  monthly: 'month',
  annually: 'year'
} as Record<string, string>

export const DomainForm = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [existsError, setExistsError] = useState(false)
  const [initialValues, setInitialValues] = useState<any>(null)

  const timeout = useRef(0 as any)
  const navigate = useNavigate()

  const { chainId } = useMetaMask()
  const registrarContract = useRegistrarContract(initialValues?.contractAddress)
  const currencies = networks?.[chainId as keyof typeof networks]?.currencies

  const { isOpen: isPremiumOpen, toggle: togglePremium } = useIsOpen()

  const getDefaultValues = (data: Domain | null) => ({
    name: data?.name ? `.${data?.name}` : '',
    url: data?.url || '',
    companyName: data?.companyName || '',
    ownerName: data?.owner?.name || '',
    ownerWallet: data?.owner?.user?.ethAddress || '',
    type: data?.payment?.type || 'free',
    limit: data?.payment?.unlimited ? '-' : data?.payment?.limit || '',
    expiresAt: data?.expiresAt || '',
    treasuryAddress: data?.treasuryAddress || '',
    serviceFee: data ? (data?.serviceFee === 0 ? '-' : data?.serviceFee) : '',
    priceOfStandart: data?.payment?.priceOfStandart || '',
    currency: data?.payment?.currency || '0x97425CfaDc3f8CcB511Ca95C59f354A9564777b6',
    priceOfPremiumA: data?.payment?.priceOfPremiumA || '',
    priceOfPremiumB: data?.payment?.priceOfPremiumB || '',
    subscription: data?.payment?.subscription || ''
  })

  const defaultValues = useMemo(() => getDefaultValues(null), [])
  const formMethods = useForm({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const values = formMethods.watch()
  const { errors, dirtyFields } = formMethods.formState

  const currenciesOptions = useMemo(() => {
    return Object.entries(currencies).map(([symbol, currency]) => {
      return {
        label: symbol,
        value: currency.token,
        icon: currency.icon
      }
    })
  }, [])

  useEffect(() => {
    if (id) {
      const fetchDomain = async () => {
        try {
          setIsLoading(true)
          const res = await apiService.getDomain(+id)
          formMethods.reset(getDefaultValues(res.data))
          setTimeout(() => setInitialValues(res.data), 0)
        } catch (error) {
          errorToast(error)
        } finally {
          setIsLoading(false)
        }
      }
      fetchDomain()
    }
  }, [id])

  useEffect(() => {
    const fetchIsPaused = async () => {
      try {
        setIsLoading(true)
        const res = await registrarContract.isDomainPaused()

        setIsPaused(res)
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    }
    if (registrarContract) {
      fetchIsPaused()
    }
  }, [registrarContract])

  const existName = async () => {
    try {
      setIsLoading(true)
      const domains = await apiService.getDomains({
        page: 1,
        offset: 10,
        search: values.name.replace('.', '')
      })
      const exists = domains.data.items.some(
        (domain: Domain) => domain.name === values.name.replace('.', '')
      )

      setExistsError(Boolean(exists))
      return Boolean(exists)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (values.name && !id) {
      clearTimeout(timeout.current)
      timeout.current = setTimeout(existName, 250)
    }
  }, [values.name, id])

  useEffect(() => {
    if (existsError && !errors.root?.name?.message) {
      formMethods.setError('root.name', {
        type: 'existsName',
        message: 'This Domain already exist'
      })
    } else if (!existsError && errors.root?.name?.message) {
      formMethods.setError('root.name', {
        type: 'existsName',
        message: ''
      })
    }
  }, [existsError, errors.root])

  useEffect(() => {
    if (
      id
        ? initialValues?.payment?.currency && values.currency !== initialValues?.payment?.currency
        : values.currency !== initialValues?.payment?.currency
    ) {
      formMethods.setValue('priceOfStandart', '', { shouldValidate: true, shouldDirty: true })
      formMethods.setValue('priceOfPremiumA', '', { shouldValidate: true, shouldDirty: true })
      formMethods.setValue('priceOfPremiumB', '', { shouldValidate: true, shouldDirty: true })
    }
  }, [values.currency, initialValues?.payment?.currency])

  const priceDecimals = useMemo(() => {
    return +currencies?.[values.currency as keyof typeof currencies]?.decimals || 6
  }, [values.currency])

  const submitForm = async (values: FieldValues) => {
    try {
      // if (Object.keys(errors).length) return

      if (!id) {
        const exists = await existName()
        if (exists) {
          formMethods.setError(
            'name',
            {
              type: 'existsName',
              message: 'This Domain already exist'
            },
            { shouldFocus: true }
          )
          return
        }
      }
      setIsLoading(true)

      const submitData = {
        name: values.name.replace('.', ''),
        url: values.url,
        companyName: values.companyName,
        ownerName: values.ownerName,
        ownerWallet: values.ownerWallet,
        treasuryAddress: values.treasuryAddress || '0x6B360fa72348D41E12c4a9c78AEaBAEAF3650eD6',
        network: 'SEPOLIA',
        serviceFee: values.serviceFee === '-' ? 0 : +values.serviceFee,
        expiresAt: values.expiresAt,
        payment: {
          type: values.type,
          currency: values.currency,
          priceOfStandart: values.priceOfStandart || 0,
          ...(values.type !== 'free' && {
            priceOfPremiumA: values.priceOfPremiumA,
            priceOfPremiumB: values.priceOfPremiumB,
            ...(values.type === 'subscription' && {
              subscription: values.subscription
            })
          }),
          limit: values.limit === '-' ? Number(MaxUint256) : +values.limit,
          unlimited: values.limit === '-'
        }
      }

      const changedData = Object.entries(submitData).reduce((acc, [key, value]: any) => {
        if (key === 'payment') {
          const payment = Object.entries(value).reduce((acc, [key, value]) => {
            return {
              ...acc,
              ...((key === 'unlimited'
                ? value !== initialValues?.payment?.unlimited
                : (dirtyFields as any)[key]) && {
                [key]: value
              })
            }
          }, {})
          if (Object.keys(payment).length) {
            return { ...acc, payment }
          }
        }

        if ((dirtyFields as any)[key]) {
          return { ...acc, [key]: value }
        }
        return acc
      }, {})

      if (initialValues) {
        const multicall = []
        const bnsInterface = new Interface(Registrar__factory.abi)

        if (initialValues?.treasuryAddress !== submitData.treasuryAddress) {
          const txData = bnsInterface.encodeFunctionData('setTreasury', [
            submitData.treasuryAddress
          ])
          multicall.push(txData)
        }

        if (initialValues?.payment?.currency !== submitData.payment.currency) {
          const txData = bnsInterface.encodeFunctionData('setPaymentToken', [
            submitData.payment.currency
          ])
          multicall.push(txData)
        }

        if (submitData.serviceFee && +(initialValues?.serviceFee || 0) !== +submitData.serviceFee) {
          const txData = bnsInterface.encodeFunctionData('setServiceFee', [
            BigInt(submitData.serviceFee)
          ])
          multicall.push(txData)
        }
        if (submitData.ownerWallet !== initialValues.owner.user.ethAddress) {
          const txData = bnsInterface.encodeFunctionData('grantRole', [
            BcRoles.DEFAULT_ADMIN_ROLE,
            submitData.ownerWallet
          ])
          multicall.push(txData)
        }

        if (submitData.expiresAt !== initialValues.expiresAt) {
          const txData = bnsInterface.encodeFunctionData('setExpirationDate', [
            BigInt(moment(submitData.expiresAt).unix())
          ])
          multicall.push(txData)
        }

        if (multicall.length) {
          const tx = await registrarContract.multicall(multicall)
          await tx.wait()
        }
      }

      if (initialValues) {
        await apiService.editDomain({
          id: initialValues.id,
          ...changedData
        })
      } else {
        await apiService.addDomain(submitData)
      }
      toast.success(initialValues ? 'Changes saved successfully' : 'Domain added successfully')
      navigate('/domains')
    } catch (error: any) {
      console.log('log => error', error)

      errorToast(error, registrarContract)
    } finally {
      setIsLoading(false)
    }
  }

  const setDomainActive = async () => {
    try {
      setIsLoading(true)

      const tx = await registrarContract.setDomainPaused(!isPaused)
      await tx.wait()
      if (initialValues?.id) {
        const isRegistrationPaused = await registrarContract.isRegistrationPaused()

        let status = moment(values.expiresAt).isAfter(moment()) ? 'active' : 'expired'
        if (!isPaused) {
          status = 'deactivated'
        } else if (isRegistrationPaused) {
          status = 'paused'
        }

        await apiService.domainAction({
          id: initialValues.id,
          status,
          actions: isPaused ? 'activatedDomain' : 'deactivatedDomain'
        })
      }
      setIsPaused(!isPaused)
      toast.success(!isPaused ? 'Domain deactivated successfully' : 'Domain activated successfully')
    } catch (error: any) {
      errorToast(error, registrarContract)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <div onClick={() => navigate('/domains')}>
        <ArrowBack />
      </div>
      {isLoading && <LoadingIndicator />}

      <PageTitle title={initialValues ? 'Edit Domain' : 'Add Domain'} />
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(submitForm)}>
          <FormField
            name="name"
            label="Domain"
            placeholder=".sxt"
            value={values.name}
            disabled={Boolean(initialValues)}
          />
          <FormField name="url" label="URL" placeholder="URL of the project" value={values.url} />
          <FormField
            name="companyName"
            label="Company name"
            placeholder="Name of the company"
            value={values.companyName}
          />
          <FormField
            name="ownerName"
            label="Domain owner name"
            placeholder="Name Surname"
            value={values.ownerName}
          />
          <FormField
            name="ownerWallet"
            label="Domain owner wallet address"
            placeholder="Specify the wallet"
            // disabled={Boolean(initialValues)}
            value={values.ownerWallet}
          />
          <FormField
            name="type"
            label="Payment method"
            value={values.type}
            type="radio"
            options={[
              { label: 'Free', value: 'free' },
              {
                label: 'One-time payment',
                value: 'oneTime'
              },
              {
                label: 'Subscription',
                value: 'subscription'
              }
            ]}
          />
          {values.type === 'subscription' && (
            <FormField
              style={{ margin: '-12px 0px 0px 32px' }}
              name="subscription"
              value={values.subscription}
              type="radio"
              options={[
                {
                  label: 'Monthly',
                  value: 'monthly'
                },
                {
                  label: 'Annually',
                  value: 'annually'
                }
              ]}
            />
          )}
          {values.type !== 'free' && (
            <>
              <PriceContainer
                {...(errors.priceOfStandart && {
                  style: {
                    alignItems: 'center'
                  }
                })}
              >
                <FormField
                  label={`Price per standard alias${
                    values.type === 'subscription' && values.subscription
                      ? ` / per ${subscriptionText[values.subscription]}`
                      : ''
                  }`}
                  name="priceOfStandart"
                  value={values.priceOfStandart}
                  placeholder="Indicate the price per alias"
                  inputType="number"
                  decimals={priceDecimals}
                />
                <FormField
                  name="currency"
                  value={values.currency}
                  type="select"
                  options={currenciesOptions}
                />
              </PriceContainer>
              <PremiumAliases open={isPremiumOpen}>
                <div className="header" onClick={() => togglePremium()}>
                  Premium aliases
                  <div className="icon">
                    <ExpandMoreIcon />
                  </div>
                </div>
                <FormField
                  label="Category A (3 letters, ex. joe.sxt)"
                  name="priceOfPremiumA"
                  value={values.priceOfPremiumA}
                  placeholder="Indicate the price"
                  inputType="number"
                  decimals={priceDecimals}
                  InputProps={{ endAdornment: Paths[chainId][values.currency]?.symbol }}
                />
                <FormField
                  label="Category B (4 letters, ex. alex.sxt)"
                  name="priceOfPremiumB"
                  value={values.priceOfPremiumB}
                  placeholder="Indicate the price"
                  inputType="number"
                  decimals={priceDecimals}
                  InputProps={{ endAdornment: Paths[chainId][values.currency]?.symbol }}
                />
              </PremiumAliases>
            </>
          )}
          <FormField
            name="limit"
            label="Limit of aliases"
            placeholder="Indicate the limit of possible aliases for the domain"
            inputType="number"
            value={values.limit !== '-' ? values.limit : ''}
            disabled={values.limit === '-'}
            decimals={0}
          />
          <CheckboxContainer
            onClick={() => {
              formMethods.setValue('limit', values.limit === '-' ? '' : '-', {
                shouldTouch: true,
                shouldValidate: true,
                shouldDirty: true
              })
            }}
          >
            <Checkbox
              sx={{ padding: '0px', color: `${colors.$blue} !important` }}
              checked={values.limit === '-'}
            />
            <div className="label">No limit</div>
          </CheckboxContainer>

          <FormField
            name="expiresAt"
            label="Expiration date"
            placeholder="Select date"
            type="date"
            value={values.expiresAt}
            minDate={moment().add(2, 'day').toISOString()}
          />
          {values.type !== 'free' && (
            <>
              <FormField
                name="treasuryAddress"
                label="Treasury wallet"
                placeholder="Specify the wallet"
                value={values.treasuryAddress}
              />
              <FormField
                name="serviceFee"
                label="Xype service fee"
                placeholder="Indicate the fee to be charged by Xype"
                value={values.serviceFee !== '-' ? values.serviceFee : ''}
                disabled={values.serviceFee === '-'}
                InputProps={{
                  endAdornment: '%'
                }}
                inputType="number"
              />
              <CheckboxContainer
                onClick={() => {
                  formMethods.setValue('serviceFee', values.serviceFee === '-' ? '' : '-', {
                    shouldTouch: true,
                    shouldValidate: true,
                    shouldDirty: true
                  })
                }}
              >
                <Checkbox
                  sx={{ padding: '0px', color: `${colors.$blue} !important` }}
                  checked={values.serviceFee === '-'}
                />
                <div className="label">No fee</div>
              </CheckboxContainer>
            </>
          )}

          <div className="actions">
            {initialValues ? (
              <>
                {isPaused ? (
                  <ButtonSuccessOutlined onClick={setDomainActive}>
                    Re-Activate
                  </ButtonSuccessOutlined>
                ) : (
                  <ButtonDangerOutlined onClick={setDomainActive}>Deactivate</ButtonDangerOutlined>
                )}
                {!isPaused && (
                  <ButtonPrimary type="submit" disabled={Boolean(errors.name)}>
                    Save Changes
                  </ButtonPrimary>
                )}
              </>
            ) : (
              <>
                <ButtonPrimaryOutlined onClick={() => navigate('/domains')}>
                  Cancel
                </ButtonPrimaryOutlined>
                <ButtonPrimary type="submit" disabled={Boolean(errors.name)}>
                  Create Domain
                </ButtonPrimary>
              </>
            )}
          </div>
        </Form>
      </FormProvider>
    </Container>
  )
}

const Container = styled.div`
  max-width: 613px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  > div:first-of-type {
    margin-bottom: 16px;
    cursor: pointer;
  }
  @media (max-width: ${MEDIA_WIDTHS.upToMedium}px) {
    max-width: 100%;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .actions {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
      flex-direction: column;
    }
  }
`

const CheckboxContainer = styled.div`
  margin-top: -12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  .label {
    color: ${colors.$secondary};
    font-size: 16px;
    font-weight: 500;
    line-height: 16px; /* 100% */
  }
`

const PriceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 160px;
  align-items: flex-end;
  gap: 12px;
`

const PremiumAliases = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  gap: 16px;
  height: 40px;
  overflow: hidden;
  background-color: #f6f6f8;
  transition: height 500ms ease-in-out, padding 500ms ease-in-out;
  .form-control-label {
    font-size: 14px;
    line-height: 100%;
  }
  .header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    color: ${colors.$secondary};
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.32px;

    > .icon {
      width: 24px;
      height: 24px;
      transform: rotate(0deg);
      transition: transform 500ms ease-in-out;
    }
  }
  ${({ open }) =>
    open &&
    css`
      padding: 16px;
      height: 236px;
      .header {
        > .icon {
          transform: rotate(180deg);
        }
      }
    `}
`
