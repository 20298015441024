import { useEffect, useMemo, useRef } from 'react'
import { toast } from 'react-toastify'
import { useDomainData } from 'src/hooks/use-domain-data'

export { AliasesPage } from './aliases'
export { ModeratorsPage } from './moderators'
export { StopWordsPage } from './stop-words'
export { IntegrationPage } from './integration'

export const DomainWrapper = ({ children }: any) => {
  const toastId = useRef(null as any)
  const timeoutId = useRef(null as any)

  const closedNotif = JSON.parse(localStorage.getItem('closedNotif') || '{}')
  const { status, id, isDeactivated } = useDomainData()

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current)
    }
  }, [status])

  useEffect(() => {
    return () => {
      toast.dismiss(toastId.current)
      toastId.current = null
    }
  }, [id])

  const needNotif = useMemo(
    () => isDeactivated || ['deactivated', 'inactive', 'expired'].includes(status),
    [status, isDeactivated]
  )

  useEffect(() => {
    if (id && needNotif && !closedNotif[id] && !toastId.current) {
      clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(() => {
        toastId.current = toast.error(
          'The domain is inactive. Contact Xype support for further details.',
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            onClick: () => {
              localStorage.setItem('closedNotif', JSON.stringify({ ...closedNotif, [id]: true }))
            }
          }
        )
      }, 250)
    }
  }, [needNotif, closedNotif[id], id])

  return children
}
