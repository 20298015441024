import React from 'react'
import { Checkbox, FormControl, MenuItem, Select as MuiSelect, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import isEqual from 'lodash/isEqual'

import { colors } from 'src/constants/colors'

export interface SelectOption {
  label: string
  value: string
}

interface Props {
  value: string[] | string
  onChange: (event: SelectChangeEvent<string[] | string>) => void
  options: { label: string; value: any }[]
  multiple?: boolean
}

export const Select = ({ value, onChange, options, multiple }: Props) => {
  const getSelectedOptionLabel = (element: string) => {
    const selectedOption = options.find((opt: SelectOption) => opt.value === element)
    if (selectedOption) return selectedOption.label
    else return ''
  }

  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      <MuiSelect
        id="item_type"
        multiple={multiple}
        value={value}
        size="medium"
        onChange={onChange}
        displayEmpty
        renderValue={(selected: string[] | string) => {
          if (!multiple) {
            return selected ? getSelectedOptionLabel(selected as string) : <>Show All</>
          }

          return !isEqual(selected, []) && Array.isArray(selected) ? (
            selected.map((element) => getSelectedOptionLabel(element)).join(', ')
          ) : (
            <>Show All</>
          )
        }}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 1
          },
          '.MuiOutlinedInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px'
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: 0
            }
          }
        }}
      >
        {options.map(({ label, value: optionValue }) => (
          <MenuItemWrapper key={label} value={optionValue}>
            {label}
            {multiple && <CheckboxStyled checked={value?.includes(optionValue)} size="small" />}
          </MenuItemWrapper>
        ))}
        {multiple && (
          <MenuItemWrapper key="select-show-all" value="all">
            Show All
            <CheckboxStyled checked={isEqual(value, []) || !value} size="small" />
          </MenuItemWrapper>
        )}
      </MuiSelect>
    </FormControl>
  )
}

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${colors.$black};
  }
`

const CheckboxStyled = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${colors.$blue};
  }
`
