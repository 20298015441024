import React, { useEffect, useState } from 'react'
import { AppBar, Button, Container, Toolbar, Typography, Link as MaterialLink } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link } from 'react-router-dom'
import { useStore } from 'effector-react'
import styled from '@emotion/styled'

import MuiTheme from 'src/theme'
import useMetaMask from 'src/hooks/useMetaMask'
import { colors } from 'src/constants/colors'
import walletIcon from 'src/assets/wallet-icon-gradient.svg'
import { isMobile } from 'src/utils/isMobile'
import { $user } from 'src/state/store'
import { SUPPORTED_CHAIN } from 'src/constants'

import { ButtonTransparent } from '../common/Button'
import { AppLogo } from '../logo'
import { AddressDropdown } from './address-dropdown'

const Header = () => {
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false)

  const { account, chainId, connect } = useMetaMask()
  const { ethereum } = window as any

  const mobile = isMobile()
  const user = useStore($user)

  const isMetamaskInstalled = typeof ethereum !== 'undefined'

  useEffect(() => {
    if (isOpenMobileNav) setIsOpenMobileNav(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile])

  const handleAddressButton = account ? (
    <AddressDropdown />
  ) : (
    <ButtonTransparentOverride
      onClick={() => connect()}
      sx={{
        color: colors.$main,
        fontWeight: 400,
        textTransform: 'initial',
        fontSize: mobile ? 12 : 16,
        background: '#F8FAFC',
        padding: '8px 16px !important'
      }}
      width="auto"
      variant="outlined"
      border="false"
      boxshadow="true"
      startIcon={
        <img
          style={{
            width: mobile ? 15 : 20,
            height: mobile ? 15 : 20,
            margin: '0 5px 0 0'
          }}
          src={walletIcon}
          alt="metamask"
        />
      }
    >
      {!isMetamaskInstalled ? (
        <MaterialLink
          href="https://metamask.io/"
          sx={{ width: '100%', textDecoration: 'none' }}
          target="_blank"
        >
          Install Metamask
        </MaterialLink>
      ) : (
        'Connect wallet'
      )}
    </ButtonTransparentOverride>
  )

  return (
    <AppBarWrapper
      sx={{
        borderBottom: `2px solid ${MuiTheme.palette.grey[200]}`,
        zIndex: 1
      }}
    >
      <Container sx={{ justifyContent: 'space-between' }}>
        <Toolbar className={account ? 'header-toolbar' : 'header-toolbar-no-acc'} disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex' } }}
          >
            <Link to="/" style={{ paddingTop: mobile ? '8px' : '5px' }}>
              <AppLogo
                width={mobile && account ? '30px' : !mobile ? '180px' : '30px'}
                size={mobile ? 'small' : ''}
                logoView={mobile ? '' : 'dark'}
              />
            </Link>
          </Typography>
          <HeaderInfoWrapper className="header-info-wrapper">
            {handleAddressButton}
            {Boolean(user) && chainId === SUPPORTED_CHAIN && (
              <StyledNavButton onClick={() => setIsOpenMobileNav(true)}>
                <MenuIcon />
              </StyledNavButton>
            )}
          </HeaderInfoWrapper>
        </Toolbar>
      </Container>
    </AppBarWrapper>
  )
}

export default Header

const StyledNavButton = styled(Button)`
  display: none;
  @media (max-width: 780px) {
    display: flex;
    align-items: center;
    min-width: 45px;
  }
`

const ButtonTransparentOverride = styled(ButtonTransparent)`
  @media (max-width: 599px) {
    width: auto;
    padding: 5px 15px;
    letter-spacing: 0.5px;

    & .MuiButton-startIcon {
      margin-right: 2px;
    }
  }
`

const AppBarWrapper = styled(AppBar)`
  background-color: transparent;
  box-shadow: none;
  position: static;

  @media (max-width: 599px) {
    & .header-toolbar {
      flex-direction: row;
      align-items: stretch;
      & .header-info-wrapper {
        justify-content: space-between;
        align-items: center;

        & h3 {
          margin-left: 0;
        }
      }
    }

    & .header-toolbar-no-acc {
      flex-direction: unset;
    }
  }
`

const HeaderInfoWrapper = styled('div')`
  display: flex;
`
