import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'

import { ButtonPrimary, EmptyStateBox, PageTitle } from 'src/components/common'
import { Domain, ResponseWithPagination } from 'src/types'
import { TablePagination } from 'src/components/table/pagination'
import apiService from 'src/services/api'
import LoadingIndicator from 'src/components/loading-indicator'

import { Filters } from './filters'
import { DomainsTable } from './domains-table'
import { toast } from 'react-toastify'

export const DomainsPage = () => {
  const [domains, setDomains] = useState<ResponseWithPagination<Domain>>(
    {} as ResponseWithPagination<Domain>
  )

  const timeout = useRef(0 as any)
  const interval = useRef(0 as any)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState<Record<string, any>>({
    search: '',
    searchByOwner: '',
    status: []
  })

  const navigate = useNavigate()

  const fetchDomains = async () => {
    try {
      setIsLoading(!interval.current)

      const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        const tempValue = Array.isArray(value) ? value.join(',') : value
        return tempValue ? { ...acc, [key]: tempValue } : acc
      }, {})

      const res = await apiService.getDomains({
        page,
        offset: 10,
        ...formattedFilters
      })

      const withInProgress = res.data.items.some((domain: Domain) => domain.status === 'inProgress')
      if (withInProgress && !interval.current) {
        interval.current = setInterval(() => fetchDomains(), 30000)
      } else if (!withInProgress) {
        clearTimeout(interval.current)
      }

      setDomains(res.data)
    } catch (error) {
      console.log('log => error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setPage(1)
  }, [filters])

  useEffect(() => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => fetchDomains(), 250)
  }, [filters, page])

  if (!domains.items) {
    return <LoadingIndicator />
  }

  const onEdit = (domain: Domain) => {
    navigate(`/domains/edit/${domain.id}`)
  }
  const onNew = () => {
    navigate(`/domains/new`)
  }

  const onRetry = async (id: number) => {
    try {
      setIsLoading(true)
      await apiService.retryAddDomain(id)
      fetchDomains()
    } catch (error) {
      setIsLoading(false)
      toast.error('Filed to retry, please try again later.')
    }
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Container>
        <Header>
          <PageTitle title="Domains" />
          <ButtonPrimary onClick={onNew} width="202px" endIcon={<AddIcon sx={{ width: '20px' }} />}>
            Add Domain
          </ButtonPrimary>
        </Header>
        <Filters filters={filters} setFilters={setFilters} />
        {domains.items.length ? (
          <>
            <DomainsTable items={domains.items} onEdit={onEdit} onRetry={onRetry} />
            <TablePagination
              page={domains.page}
              handleChange={(e, newPage) => setPage(newPage)}
              totalItems={domains.totalItems}
              totalPages={domains.totalPages}
            />
          </>
        ) : (
          <EmptyStateBox />
        )}
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`
