export class StorageService {
  static clearStorage() {
    window.localStorage.removeItem('walletAddr')
    window.localStorage.removeItem('auth')
  }

  static setAuthTokens(walletAddr: string, authObject: any) {
    const auth = window.localStorage.getItem('auth') as any
    try {
      return window.localStorage.setItem(
        'auth',
        JSON.stringify({
          ...JSON.parse(auth),
          [walletAddr]: authObject
        })
      )
    } catch (e) {
      return window.localStorage.setItem(
        'auth',
        JSON.stringify({
          [walletAddr]: authObject
        })
      )
    }
  }
}
