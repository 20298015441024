import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import AddIcon from '@mui/icons-material/Add'
import { namehash, keccak256, toUtf8Bytes, ZeroAddress, Interface } from 'ethers'
import { toast } from 'react-toastify'

import {
  ButtonPrimary,
  ButtonPrimaryOutlined,
  DomainName,
  EmptyStateBox
} from 'src/components/common'
import { TablePagination } from 'src/components/table/pagination'
import { useIsOpen } from 'src/hooks/use-is-open'
import { Alias, ResponseWithPagination } from 'src/types'
import LoadingIndicator from 'src/components/loading-indicator'
import { useRegistrarContract, useResolverContract } from 'src/hooks/useContracts'
import apiService from 'src/services/api'
import { useDomainData } from 'src/hooks/use-domain-data'
import { ConfirmModal } from 'src/components/modal/confirm-modal'

import { Filters } from './filters'
import { AliasesTable } from './aliases-table'
import { AliasModal } from './alias-modal'
import { errorToast } from 'src/utils'
import { setDomain } from 'src/state/store'
import moment from 'moment'
import { Registrar__factory } from 'src/contract-types'

export const AliasesPage = () => {
  const [aliases, setAliases] = useState<ResponseWithPagination<Alias> | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<{ search: string; status: string[] }>({
    search: '',
    status: []
  })
  const [editData, setEditData] = useState<Alias | null>(null)
  const [aliasToDelete, setAliasToDelete] = useState<Alias | null>(null)

  const { isOpen: isAddOpen, close: closeAdd, open: openAdd } = useIsOpen()
  const { isOpen: isReserveOpen, close: closeReserve, open: openReserve } = useIsOpen()
  const { isOpen: isConfirmOpen, open: openConfirm, close: closeConfirm } = useIsOpen()

  const timeout = useRef(0 as any)
  const { id, contractAddress, payment, status, isDeactivated, isPaused, expiresAt } =
    useDomainData()
  const resolverContract = useResolverContract()
  const registrarContract = useRegistrarContract(contractAddress)

  const paginationAliases = useMemo(() => {
    if (!aliases) return []
    return aliases?.items?.slice((page - 1) * 10, page * 10)
  }, [page, aliases])

  useEffect(() => {
    setAliases(null)
    setFilters({ search: '', status: [] })
    setPage(1)
  }, [id])

  const getPrimaryName = async (address: string) => {
    try {
      const node = namehash(address.substring(2).toLowerCase() + '.addr.reverse')
      const name = await resolverContract.name(node)
      return name
    } catch (error) {
      return ''
    }
  }

  const fetchAliases = async () => {
    try {
      setIsLoading(true)
      const res = await apiService.getAliases({
        id,
        offset: 10,
        page,
        search: filters.search,
        ...(filters.status.length && {
          status: filters.status.join(',')
        })
      })
      const primaryAliases = await Promise.all(
        res.data.items.map(
          (item: Alias) => (item.ownerAddress ? getPrimaryName(item.ownerAddress) : ''),
          []
        )
      )

      setAliases({
        ...res.data,
        items: res.data.items
          .map((item: Alias, index: number) => ({
            ...item,
            isPrimary: primaryAliases[index] === item.name,
            primaryAlias: primaryAliases[index]
          }))
          .filter((alias: Alias) => alias.ownerAddress !== ZeroAddress)
      })
    } catch (error) {
      console.error(error)
    } finally {
      setPage(1)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!id) return
    clearTimeout(timeout.current)
    timeout.current = setTimeout(fetchAliases, 250)
  }, [id, filters])

  const onClose = (needRefresh = false) => {
    if (needRefresh) {
      fetchAliases()
    }
    if (editData) setEditData(null)
    if (isAddOpen) closeAdd()
    if (isReserveOpen) closeReserve()
  }

  const setPaused = async () => {
    try {
      setIsLoading(true)
      const tx = await registrarContract.setRegistrationPaused(!isPaused)
      await tx.wait()
      const res = await apiService.domainAction({
        id,
        status: isPaused ? (moment(expiresAt).isAfter(moment()) ? 'active' : 'expired') : 'paused',
        actions: isPaused
          ? 'unpausedDomain,unpausedRegistration'
          : 'domainPaused,pausedRegistration'
      })
      setDomain(res.data)
      toast.success(isPaused ? 'Domain unpaused successfully' : 'Domain paused successfully')
    } catch (error: any) {
      errorToast(error, registrarContract)
    } finally {
      setIsLoading(false)
    }
  }

  const onDelete = async () => {
    try {
      if (!aliasToDelete) return
      setIsLoading(true)
      closeConfirm()

      if (aliasToDelete.ownerAddress) {
        const bnsInterface = new Interface(Registrar__factory.abi)
        const multicall = []
        const txDataDelete = bnsInterface.encodeFunctionData('deleteAlias', [
          [aliasToDelete.name.split('.')[0]],
          aliasToDelete.ownerAddress,
          true
        ])

        multicall.push(txDataDelete)

        if (aliasToDelete.isPrimary && aliases?.items?.length) {
          const newPrimaryAlias = aliases?.items.find(
            (el) => el.ownerAddress === aliasToDelete.ownerAddress && el.name !== aliasToDelete.name
          )
          if (newPrimaryAlias) {
            const txDataSetPrimary = bnsInterface.encodeFunctionData('setPrimary', [
              newPrimaryAlias.name.split('.')[0],
              aliasToDelete.ownerAddress
            ])

            multicall.push(txDataSetPrimary)
          }
        }

        const tx = await registrarContract.multicall(multicall)
        await tx.wait()

        const backRequests = [
          apiService.deleteAliasLog({
            id,
            aliases: [aliasToDelete.name],
            wallet: aliasToDelete.ownerAddress
          })
        ]

        if (multicall[1]) {
          backRequests.push(
            apiService.changePrimaryAliasLog({
              id,
              wallet: aliasToDelete.ownerAddress
            })
          )
        }

        await Promise.all(backRequests)
      } else {
        await apiService.deleteReservedAlias(aliasToDelete.id)
      }
      setAliasToDelete(null)
      toast.success('Alias successfully deleted')
      await fetchAliases()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const openDelete = (alias: Alias) => {
    setAliasToDelete(alias)
    openConfirm()
  }

  const disableActions = useMemo(
    () => isDeactivated || ['deactivated', 'inactive', 'expired'].includes(status),
    [status, isDeactivated]
  )

  if (!aliases) {
    return <LoadingIndicator />
  }

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      {(isAddOpen || isReserveOpen || editData) && (
        <AliasModal initialValues={editData} handleClose={onClose} isReserve={isReserveOpen} />
      )}
      <ConfirmModal
        title="Do you want to delete Alias?"
        isOpen={isConfirmOpen}
        onConfirm={onDelete}
        onClose={closeConfirm}
      />
      <DomainName />

      <Header>
        <SectionLabel>Aliases</SectionLabel>
        <div className="actions">
          <ButtonPrimaryOutlined onClick={setPaused} disabled={disableActions}>
            {isPaused ? 'Unpause Registration' : 'Pause Registration'}
          </ButtonPrimaryOutlined>
          <ButtonPrimaryOutlined onClick={openReserve} disabled={disableActions || isPaused}>
            Reserve Alias
          </ButtonPrimaryOutlined>
          <ButtonPrimary
            onClick={openAdd}
            width="202px"
            endIcon={<AddIcon sx={{ width: '20px' }} />}
            disabled={disableActions || isPaused}
          >
            Add Alias
          </ButtonPrimary>
        </div>
      </Header>

      <Filters filters={filters} setFilters={setFilters} />
      {paginationAliases.length ? (
        <>
          <AliasesTable
            items={paginationAliases}
            setEditData={setEditData}
            onDelete={openDelete}
            disableActions={disableActions || isPaused}
          />
          <TablePagination
            page={page}
            handleChange={(e: any, newPage) => setPage(newPage)}
            totalItems={aliases.items.length}
            totalPages={Math.ceil(aliases.items.length / 10)}
          />
        </>
      ) : (
        <EmptyStateBox />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .actions {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
  }
`
const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`
