import React from 'react'
import './index.scss'
// import './assets/scss/variables.scss';
import './assets/scss/mixins.scss'
import './fonts/SpaceGrotesk-Bold.ttf'
import './fonts/SpaceGrotesk-Light.ttf'
import './fonts/SpaceGrotesk-Medium.ttf'
import './fonts/SpaceGrotesk-Regular.ttf'
import './fonts/SpaceGrotesk-SemiBold.ttf'
import { ThemeProvider } from '@emotion/react'
import { createRoot } from 'react-dom/client'
import Web3 from 'web3'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { Web3ReactProvider } from '@web3-react/core'

import App from './pages/app'
import MuiTheme from './theme'
import { CssBaseline } from '@mui/material'
import { MetaMaskProvider } from './hooks/useMetaMask'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DoneIcon from '@mui/icons-material/Done'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
// import * as Sentry from '@sentry/react'
// import { BrowserTracing } from '@sentry/tracing'
import ErrorBoundary from 'src/components/error-boundary'

// if (window.location.hostname === 'app.sixthsociety.com') {
//   Sentry.init({
//     dsn: 'https://e9a03da0120e48eba54684a8f85f7ec7@o4504457678094336.ingest.sentry.io/4504457831317504',
//     integrations: [
//       new BrowserTracing(),
//       new Sentry.Replay({ maskAllText: false, blockAllMedia: false })
//     ],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,

//     // This sets the sample rate to be 10%. You may want this to be 100% while
//     // in development and sample at a lower rate in production
//     replaysSessionSampleRate: 0.1,

//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     replaysOnErrorSampleRate: 1.0
//   })
// }

const client = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/proxy/29505/bns-subgraph-test/version/latest/',
  cache: new InMemoryCache()
})

const root = createRoot(document.getElementById('root') as HTMLElement)

const getLibrary = (provider: unknown | any, _connector: any) => new Web3(provider)

root.render(
  <ThemeProvider theme={MuiTheme}>
    <CssBaseline enableColorScheme />
    <Web3ReactProvider getLibrary={getLibrary}>
      <MetaMaskProvider>
        <ErrorBoundary>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </ErrorBoundary>
        <ToastContainer
          icon={({ type }) => (type === 'success' ? <DoneIcon /> : <ErrorOutlineIcon />)}
          theme="colored"
          toastStyle={{ fontWeight: 600 }}
          closeButton={false}
          hideProgressBar
        />
      </MetaMaskProvider>
    </Web3ReactProvider>
  </ThemeProvider>
)
