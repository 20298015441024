import React, { useState } from 'react'
import { css, TableContainer } from '@mui/material'
import styled from '@emotion/styled'
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useNavigate } from 'react-router-dom'

import editIcon from 'src/assets/edit.svg'
import retryIcon from 'src/assets/retry.svg'
import { colors } from 'src/constants/colors'
import { Domain } from 'src/types'
import { Table, HeaderRow, BodyRow, CellPrimary } from 'src/components/table'
import { Dropdown } from 'src/components/common'
import { AddressesCell } from 'src/components/table/addresses-cell'

import LoadingIndicator from 'src/components/loading-indicator'

const headerData: string[] = ['TLD', 'Url', 'Owner', 'Created at', 'Expires at', 'Status', '']

const statusColors = {
  active: colors.$success,
  expired: colors.$error,
  deactivated: colors.$error,
  inactive: colors.$primaryLight,
  inProgress: colors.$lightYellow,
  paused: colors.$lightYellow
} as Record<string, string>

const statusLabel = {
  inactive: 'Paused',
  inProgress: 'In progress'
} as Record<string, string>

interface Props {
  items: Domain[]
  onEdit: (item: Domain) => void
  onRetry: (id: number) => void
}

export const DomainsTable = ({ items, onEdit, onRetry }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      {isLoading && <LoadingIndicator />}

      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: '700px' }}>
          <HeaderRow rowTemplate={rowTemplate}>
            {headerData.map((header) => (
              <CellPrimary key={header}>{header}</CellPrimary>
            ))}
          </HeaderRow>
          <>
            {items.map((row) => (
              <DomainRow key={row.id} domain={row} onEdit={onEdit} onRetry={onRetry} />
            ))}
          </>
        </Table>
      </TableContainer>
    </>
  )
}

interface DomainRowProps {
  domain: Domain
  onEdit: (item: Domain) => void
  onRetry: (id: number) => void
}

const DomainRow = ({ domain, onEdit, onRetry }: DomainRowProps) => {
  const navigate = useNavigate()

  const goToDomainUrl = (url: string) => {
    window.open(url, '_blank')
  }

  const goToDomain = (name: string) => {
    navigate(`/domain/.${name}/aliases`)
  }

  return (
    <>
      <BodyRow rowTemplate={rowTemplate}>
        <CellPrimary>
          <ViewBtn onClick={() => goToDomain(domain.name)}>.{domain.name}</ViewBtn>
        </CellPrimary>
        <CellPrimary
          style={{
            overflow: 'hidden'
          }}
        >
          <ViewBtn onClick={() => goToDomainUrl(domain.url)}>
            {domain.url.replace('https://', '')}
          </ViewBtn>
        </CellPrimary>
        <CellPrimary>
          {domain.owner?.name ? (
            <AddressesCell name={domain.owner?.name} id={`${domain.id}_owner`} />
          ) : (
            '-'
          )}
        </CellPrimary>
        <CellPrimary>{moment(domain.createdAt).format('DD MMM YYYY')}</CellPrimary>
        <CellPrimary>{moment(domain.expiresAt).format('DD MMM YYYY')}</CellPrimary>
        <CellPrimary style={{ color: statusColors[domain.status], textTransform: 'capitalize' }}>
          {statusLabel[domain.status] || domain.status}
        </CellPrimary>
        <CellPrimary>
          <Dropdown
            menuContent={
              <ItemsContainer>
                <Item onClick={() => onEdit(domain)}>
                  <img src={editIcon} alt="editIcon" />
                  Edit
                </Item>
                {domain.status === 'failed' && (
                  <Item onClick={() => onRetry(domain.id)}>
                    <img src={retryIcon} alt="retryIcon" />
                    Retry
                  </Item>
                )}
              </ItemsContainer>
            }
          >
            <MoreVertIcon fontSize="small" className="moreIcon" />
          </Dropdown>
        </CellPrimary>
      </BodyRow>
    </>
  )
}

const rowTemplate = css`
  grid-template-columns: repeat(5, 1fr) 120px 50px;

  a {
    text-decoration: underline;
  }
  .moreIcon {
    fill: ${colors.$blue};
    cursor: pointer;
  }
`

const ViewBtn = styled.div`
  cursor: pointer;
  color: ${colors.$blue} !important;
  font-weight: 500;
  text-decoration-line: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Item = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.$primary2};
  width: 140px;
  &:hover {
    border-radius: 3px;
  }
`
