import { ErrorDescription, isBytesLike } from 'ethers'
import { toast } from 'react-toastify'

export const debounce = (fn = (...args: any) => {}, delay = 250) => {
  let timerId = null as any
  return (...args: any) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => fn(...args), delay)
  }
}

export const errorToast = (error: any, contract?: any) => {
  if (error.code === 'ACTION_REJECTED') return

  const errorMessages = {
    RegistrarDomainIsPaused: 'Domain is deactivated',
    RegistrarAliasesOutOfLimit: 'Max aliases added',
    RegistrarInvalidAliasesLimit: 'Limit cannot be less than already added aliases'
  } as Record<string, string>

  let message = error?.response?.data?.message || error.message || 'Failed, please try again'

  if (isBytesLike(error.data) && contract) {
    const decodedError = contract.interface.parseError(error.data) as ErrorDescription
    message = decodedError.name
  }
  if (message.includes('bad address checksum')) {
    message = 'Invalid Ethereum address'
  }
  toast.error(errorMessages[message] || message)
}
