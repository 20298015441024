import React from 'react'
import styled from '@emotion/styled'
import { colors } from '../../constants/colors'

const LoadingIndicator = () => <Loader />
export const BoxLoadingIndicator = () => <LoaderBox />

interface LoaderContainerProps {
  height?: string
}

export const LoaderContainer: React.FC<LoaderContainerProps> = ({ height }) => (
  <StyledLoaderContainer height={height} />
)

export default LoadingIndicator

const Loader = styled('div')`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid ${colors.$successOrange};
    border-right: 3px solid transparent;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const LoaderBox = styled('div')`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid ${colors.$successOrange};
    border-right: 3px solid transparent;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StyledLoaderContainer = styled(LoaderBox)<LoaderContainerProps>`
  height: ${({ height }) => height || '100px'};
  display: flex;
  justify-content: center;
  position: static;
`
