import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useForm, FormProvider, FieldValues } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { Moderator } from 'src/types'
import CustomModal from 'src/components/modal/custom-modal'
import {
  ButtonPrimary,
  FormField,
  ButtonPrimaryOutlined,
  ButtonDangerOutlined,
  ButtonSuccessOutlined
} from 'src/components/common'
import { BcRoles, MEDIA_WIDTHS } from 'src/constants'
import { useIsOpen } from 'src/hooks/use-is-open'
import { ConfirmModal } from 'src/components/modal/confirm-modal'
import LoadingIndicator from 'src/components/loading-indicator'
import apiService from 'src/services/api'
import { useDomainData } from 'src/hooks/use-domain-data'

import { validationSchema } from './validation-schema'
import { useRegistrarContract } from 'src/hooks/useContracts'
import { errorToast } from 'src/utils'

interface Props {
  handleClose: (needRefresh?: boolean) => void
  initialValues: Moderator | null
}

export const ModeratorModal = ({ handleClose, initialValues }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const { isOpen: isConfirmOpen, close: closeConfirm, open: openConfirm } = useIsOpen()
  const {
    isOpen: isChangeDeactivateOpen,
    close: closeChangeDeactivate,
    open: openChangeDeactivate
  } = useIsOpen()

  const { id: domainId, contractAddress } = useDomainData()
  const registrarContract = useRegistrarContract(contractAddress)

  const formMethods = useForm({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues: {
      wallet: initialValues?.user?.ethAddress || '',
      name: initialValues?.name || '',
      deactivated: initialValues?.deactivated || false
    },
    resolver: yupResolver(validationSchema)
  })

  const values = formMethods.watch()

  const submitForm = async (values: FieldValues) => {
    try {
      setIsLoading(true)
      closeConfirm()
      if (initialValues?.id) {
        await apiService.editModerator({
          id: initialValues.id,
          name: values.name,
          deactivated: initialValues.deactivated
        })
      } else {
        const validation = await apiService.validateAddModerator(domainId, values.wallet)

        if (!validation.data.isValid) {
          throw new Error('Wallet already in team')
        }

        const tx = await registrarContract.grantRole(BcRoles.MODERATOR_ROLE, values.wallet)
        await tx.wait()

        await apiService.addModerator({ id: domainId, name: values.name, wallet: values.wallet })
      }

      toast.success(initialValues ? 'Changes saved successfully' : 'Moderator added successfully')
      handleClose(true)
    } catch (error: any) {
      errorToast(error, registrarContract)
    } finally {
      setIsLoading(false)
    }
  }

  const onConfirm = () => {
    submitForm(values)
  }

  const changeDeactivate = async () => {
    try {
      setIsLoading(true)
      closeConfirm()
      if (initialValues) {
        if (!values.deactivated) {
          const tx = await registrarContract.revokeRole(BcRoles.MODERATOR_ROLE, values.wallet)
          await tx.wait()
        } else {
          const tx = await registrarContract.grantRole(BcRoles.MODERATOR_ROLE, values.wallet)
          await tx.wait()
        }
        await apiService.editModerator({
          id: initialValues.id,
          name: values.name,
          deactivated: !values.deactivated
        })
        toast.success(
          `Moderator ${!values.deactivated ? 'deactivated' : 'reactivated'} successfully`
        )
        handleClose(true)
      }
    } catch (error) {
      errorToast(error, registrarContract)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <ConfirmModal
        title={
          initialValues
            ? 'Do you want to save changes in Moderator?'
            : 'Do you want to add Moderator?'
        }
        isOpen={isConfirmOpen}
        onConfirm={onConfirm}
        onClose={closeConfirm}
      />
      <ConfirmModal
        title={
          values.deactivated
            ? 'Do you want to re-activate Moderator?'
            : 'Do you want to deactivate Moderator?'
        }
        isOpen={isChangeDeactivateOpen}
        onConfirm={changeDeactivate}
        onClose={closeChangeDeactivate}
      />
      <CustomModal
        isOpen
        handleClose={handleClose}
        title={initialValues ? 'Edit Moderator' : 'Add Moderator'}
      >
        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(openConfirm)}>
            <FormField name="name" label="Name" placeholder="Name Surname" value={values.name} />
            <FormField
              disabled={Boolean(initialValues)}
              name="wallet"
              label="Wallet address"
              placeholder="Specify the wallet"
              value={values.wallet}
            />

            <div className="actions">
              {initialValues ? (
                <>
                  <ButtonPrimary type="submit" fullWidth>
                    Save Changes
                  </ButtonPrimary>
                  {values.deactivated ? (
                    <ButtonSuccessOutlined fullWidth onClick={openChangeDeactivate}>
                      Re-Activate
                    </ButtonSuccessOutlined>
                  ) : (
                    <ButtonDangerOutlined fullWidth onClick={openChangeDeactivate}>
                      Deactivate
                    </ButtonDangerOutlined>
                  )}
                </>
              ) : (
                <>
                  <ButtonPrimaryOutlined fullWidth onClick={() => handleClose()}>
                    Cancel
                  </ButtonPrimaryOutlined>
                  <ButtonPrimary type="submit" fullWidth>
                    Add Moderator
                  </ButtonPrimary>
                </>
              )}
            </div>
          </Form>
        </FormProvider>
      </CustomModal>
    </>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .actions {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
      flex-direction: column;
    }
  }
`
