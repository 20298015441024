import React from 'react'
import { Box, CardMedia, Grid, Stack, styled, Typography, Paper } from '@mui/material'
import { ContainerWrapper } from '../main'
import notFound404 from '../../assets/404.svg'
import { ButtonPrimaryTransparent } from 'src/components/common'
import { colors } from 'src/constants/colors'
import { useNavigate } from 'react-router-dom'
import { isMobile, isTablet } from 'src/utils/isMobile'

export const NotFoundPage = () => {
  const navigate = useNavigate()
  const mobile = isMobile()
  const tablet = isTablet()

  return (
    <ContainerWrapper>
      <Box sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Item sx={{ display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                className="not-fount-image"
                sx={{
                  maxWidth: mobile ? '270px' : tablet ? '370px' : '540px',
                  height: 'auto'
                }}
                component="img"
                src={notFound404}
                alt="SixthSociety Not Found"
              />
            </Item>
            <Item>
              <Typography
                variant="subtitle1"
                color={colors.$primary}
                textTransform="capitalize"
                fontSize={mobile || tablet ? '34px' : '48px'}
                fontWeight={700}
              >
                oops!
              </Typography>
            </Item>
            <Item>
              <Typography
                variant="h4"
                color={colors.$secondary}
                textTransform="inherit"
                fontSize={mobile || tablet ? '14px' : '16px'}
                fontWeight={400}
              >
                The page you’re looking for isn’t working :(
              </Typography>
            </Item>
            <Item>
              <ButtonPrimaryTransparent
                mobile={mobile ? 1 : 0}
                onClick={() => navigate('/')}
                sx={{ marginTop: mobile || tablet ? '15px' : '30px' }}
              >
                Go to the Homepage
              </ButtonPrimaryTransparent>
            </Item>
          </Stack>
        </Grid>
      </Box>
    </ContainerWrapper>
  )
}

const Item = styled(Paper)`
  box-shadow: none;
  display: flex;
  justify-content: center;
  text-align: center;

  @media (max-width: 768px) {
    .not-fount-image {
      max-width: 370px;
    }
  }
  @media (max-width: 599px) {
    .not-fount-image {
      max-width: 270px;
    }
  }
`
