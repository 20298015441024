import React from 'react'
import { Checkbox, FormControl, MenuItem, Select, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import isEqual from 'lodash/isEqual'

import { colors } from 'src/constants/colors'
import { useDomainData } from 'src/hooks/use-domain-data'

export interface SelectOption {
  label: string
  value: string
}

// addedAdmin = 'addedAdmin',
// addedDomain = 'addedDomain',
// addedAlias = 'addedAlias',
// addedModerator = 'addedModerator',
// activatedAdmin = 'activatedAdmin',
// activatedDomain = 'activatedDomain',
// activatedModerator = 'activatedModerator',
// deactivatedAdmin = 'deactivatedAdmin',
// deactivatedDomain = 'deactivatedDomain',
// deactivatedModerator = 'deactivatedModerator',
// pausedDomain = 'pausedDomain',
// unpausedDomain = 'unpausedDomain',
// editedDomain = 'editedDomain',
// editedAlias = 'editedAlias',
// changedPrimaryAlias = 'changedPrimaryAlias',
// reservedAlias = 'reservedAlias',
// pausedRegistration = 'pausedRegistration',
// unpausedRegistration = 'unpausedRegistration',
// deleteAlias = 'deleteAlias',
// addedStopWords = 'addedStopWords',

const globalOptions: SelectOption[] = [
  { label: 'Added admin', value: 'addedAdmin' },
  { label: 'Deactivated admin', value: 'deactivatedAdmin' },
  { label: 'Activated admin', value: 'activatedAdmin' },
  { label: 'Renamed admin', value: 'renamedAdmin' },
  { label: 'Added domain', value: 'addedDomain' },
  { label: 'Deactivated domain', value: 'deactivatedDomain' },
  { label: 'Activated domain', value: 'activatedDomain' },
  { label: 'Paused domain', value: 'pausedDomain' },
  { label: 'Unpaused domain', value: 'unpausedDomain' },
  { label: 'Edited domain ', value: 'editedDomain' }
]

const domainOptions: SelectOption[] = [
  { label: 'Added moderator', value: 'addedModerator' },
  { label: 'Deactivated moderator', value: 'deactivatedModerator' },
  { label: 'Activated moderator', value: 'activatedModerator' },
  { label: 'Renamed moderator', value: 'renamedModerator' },
  { label: 'Added alias', value: 'addedAlias' },
  { label: 'Deleted alias', value: 'deleteAlias' },
  { label: 'Changed primary alias', value: 'changedPrimaryAlias' },
  { label: 'Reserved alias', value: 'reservedAlias' },
  { label: 'Paused registration', value: 'pausedRegistration' },
  { label: 'Unpaused registration', value: 'unpausedRegistration' },
  { label: 'Added stop words', value: 'addedStopWords' }
]

interface Props {
  value: string[]
  onChange: (event: SelectChangeEvent<string[]>) => void
}

export const ActionTypeSelect = ({ value, onChange }: Props) => {
  const { id } = useDomainData()
  const options = id ? domainOptions : globalOptions

  const getSelectedOptionLabel = (element: string) => {
    const selectedOption = options.find((opt: SelectOption) => opt.value === element)
    if (selectedOption) return selectedOption.label
    else return ''
  }

  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      <Select
        id="item_type"
        multiple
        value={value}
        size="medium"
        onChange={onChange}
        displayEmpty
        renderValue={(selected: string[]) => {
          return !isEqual(selected, []) ? (
            selected.map((element) => getSelectedOptionLabel(element)).join(', ')
          ) : (
            <>Show All Actions</>
          )
        }}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 1
          },
          '.MuiOutlinedInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px'
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: 0
            }
          }
        }}
      >
        {options.map(({ label, value: optionValue }) => (
          <MenuItemWrapper key={label} value={optionValue}>
            {label}
            <CheckboxStyled checked={value?.includes(optionValue)} size="small" />
          </MenuItemWrapper>
        ))}
        <MenuItemWrapper key="select-show-all" value="all">
          Show All Actions
          <CheckboxStyled checked={isEqual(value, [])} size="small" />
        </MenuItemWrapper>
      </Select>
    </FormControl>
  )
}

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${colors.$black};
  }
`

const CheckboxStyled = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${colors.$blue};
  }
`
