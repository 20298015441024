import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'effector-react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, useMediaQuery } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import {
  $domainsList,
  $isMaintenanceModeActive,
  $isShowNavigation,
  setIsShowNavigation
} from 'src/state/store'
import adminsIcon from 'src/assets/people_outlined_icon.svg'
import dropdownIcon from 'src/assets/dropdown.svg'
import domainsIcon from 'src/assets/globe.svg'
import logsIcon from 'src/assets/logs.svg'
import { UserRoles } from 'src/constants'
import { useUserRole } from 'src/hooks/useAccessController'

import {
  NavigationWrapper,
  MenuWrapper,
  NavListWrapper,
  NavLinkWrapper,
  Footer,
  NavItemText,
  LinkOverrides,
  Hr,
  DomainsMenu,
  DomainRow
} from './styles'

interface NavList {
  name: string
  path: string
  icon: JSX.Element
  isNotAllowed?: (role: UserRoles) => boolean
}

const navList: NavList[] = [
  {
    name: 'Domains',
    path: '/domains',
    icon: <img src={domainsIcon} />
  },
  {
    name: 'Admins',
    path: '/admins',
    icon: <img src={adminsIcon} />
  },
  {
    name: 'Logs',
    path: '/logs',
    icon: <img src={logsIcon} />
  }
]

const menuList = ['Aliases', 'Moderators', 'Stop Words', 'Integration', 'Logs']

export const Navigation = () => {
  const [extendedDomain, setExtendedDomain] = useState(0)

  const domains = useStore($domainsList)
  const isShowNavigation = useStore($isShowNavigation)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)

  const { domain = '' } = useParams<{ domain?: string }>()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isLaptop = useMediaQuery('@media(min-width: 1000px)')

  const userRole = useUserRole()

  useEffect(() => {
    if (domain && domains.length) {
      const selectedId = domains.find(({ name }) => name === domain)?.id
      if (selectedId) {
        setExtendedDomain(selectedId)
      }
    }
  }, [domain, domains])

  const handleMenu = () => !isLaptop && setIsShowNavigation(false)

  const currentYear = useMemo(() => {
    return new Date().getFullYear()
  }, [])

  useEffect(() => {
    if (!isLaptop) setIsShowNavigation(false)
  }, [isLaptop])

  const goToDomainPage = (name: string, page: string) => {
    navigate(`/domain/${name}/${page}`)
  }

  const isDomainPageActive = (name: string, page: string) => {
    const [urlName, urlPage] = pathname.replace('/domain/', '').split('/')

    return urlName === name && urlPage === page
  }

  const goToHomePage = () => {
    navigate('/')
  }

  return (
    <NavigationWrapper
      isFullMode={true}
      mobile={!isLaptop}
      isShow={isShowNavigation}
      isMaintenanceModeActive={isMaintenanceModeActive}
    >
      <MenuWrapper isFullMode={true}>
        <Box height={20}>
          <MenuIcon fontSize="small" onClick={handleMenu} />
        </Box>
        <NavItemText isFullMode={true} onClick={goToHomePage}>
          <Box display="flex" alignItems="center" fontSize={18}>
            Xype
          </Box>
        </NavItemText>
      </MenuWrapper>
      {[UserRoles.SuperAdmin, UserRoles.Admin].includes(userRole) && (
        <NavListWrapper isFullMode={true}>
          {navList.length
            ? navList.map((item, idx) =>
                item.isNotAllowed && item.isNotAllowed(userRole) ? null : (
                  <NavLinkWrapper
                    key={`${item.name}-${idx}`}
                    // to={item.path}
                    className={pathname === item.path ? 'active' : ''}
                    isFullMode={true}
                    onClick={() => {
                      navigate(item.path)
                      if (!isLaptop) setIsShowNavigation(false)
                    }}
                  >
                    <div className="icon">{item.icon}</div>
                    <NavItemText isFullMode={true}>{item.name}</NavItemText>
                  </NavLinkWrapper>
                )
              )
            : null}
        </NavListWrapper>
      )}
      <Hr style={{ marginTop: userRole !== UserRoles.SuperAdmin ? '46px' : '0px' }} />

      <DomainsMenu>
        <div className="title">My domains</div>
        {domains.map(({ name, id }, index) => (
          <DomainRow
            open={extendedDomain === id}
            key={id}
            className={`${pathname.includes(name) ? 'active' : ''}`}
          >
            <div
              className="header"
              onClick={() => {
                setExtendedDomain(extendedDomain === id ? 0 : id)
              }}
            >
              <img className="arrow" src={dropdownIcon} alt="dropdownIcon" />
              <div className="label">{name}</div>
            </div>
            <div className="list">
              {menuList.map((item) => (
                <div
                  key={item}
                  className={
                    isDomainPageActive(name, item.toLowerCase().replaceAll(' ', '-'))
                      ? 'active-item'
                      : ''
                  }
                  onClick={() => {
                    goToDomainPage(name, item.toLowerCase().replaceAll(' ', '-'))
                    if (!isLaptop) setIsShowNavigation(false)
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </DomainRow>
        ))}
      </DomainsMenu>

      {/* {!isShowFullMenu && (
        <SwitchContainerSm>
          <MaintenanceModeSwitcher />
        </SwitchContainerSm>
      )} */}

      {isLaptop ? (
        <>
          <Footer>
            <Hr />
            <div className="content">
              {/* <SwitchContainer>
              <MaintenanceModeSwitcher isFullMode={isShowFullMenu} />
            </SwitchContainer> */}
              <div>© {currentYear} Xype Ltd.</div>
              <div>
                <LinkOverrides to={'/privacy-policy'} target="_blank">
                  <div>{`Privacy & Cookies Policy`}</div>
                </LinkOverrides>
              </div>
            </div>
          </Footer>
        </>
      ) : null}
    </NavigationWrapper>
  )
}
